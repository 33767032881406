import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileInvoice, faFileContract, faUserCircle, faBriefcase, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import NavigationBar from '../LandingPage.js/NavigationBar';
import Footer from '../LandingPage.js/Footer';

const headerTexts = [
  'Welcome to the 30-Day Klorah Creator Challenge',
  'Get clients in 30 days or your money back',
  'Elevate your creative business',
  'Build a stunning profile',
  'Connect with clients',
  'Grow faster than ever',
];

const FeatureCard = ({ icon, title, description }) => {
  return (
    <motion.div 
      whileHover={{ scale: 1.05 }}
      className="bg-black bg-opacity-10 p-6 rounded-xl overflow-hidden backdrop-filter backdrop-blur-lg shadow-lg border border-white"
    >
      <FontAwesomeIcon icon={icon} size="2x" className="mb-4 text-white" />
      <h4 className="text-xl font-bold mb-2 text-white">{title}</h4>
      <p className="text-white opacity-80">{description}</p>
    </motion.div>
  );
};

const InfoBox = ({ title, description }) => {
  return (
    <div className="bg-orange-600 bg-opacity-70 p-6 rounded-xl shadow-lg">
      <h3 className="text-xl font-bold mb-2 text-white">{title}</h3>
      <p className="text-white opacity-80">{description}</p>
    </div>
  );
};

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="border-b border-white py-4">
      <button
        className="flex justify-between items-center w-full text-left"
        onClick={() => setIsOpen(!isOpen)}
      >
        <h3 className="text-lg font-semibold text-white">{question}</h3>
        <FontAwesomeIcon
          icon={faChevronDown}
          className={`text-white transition-transform duration-200 ${
            isOpen ? 'transform rotate-180' : ''
          }`}
        />
      </button>
      {isOpen && <p className="mt-2 text-white opacity-80">{answer}</p>}
    </div>
  );
};

export default function CreatorChallengeLandingPage() {
  const [currentHeaderIndex, setCurrentHeaderIndex] = useState(0);
  const [typedText, setTypedText] = useState('');

  useEffect(() => {
    let currentIndex = 0;
    const currentText = headerTexts[currentHeaderIndex];

    const typingInterval = setInterval(() => {
      setTypedText(currentText.slice(0, currentIndex));
      currentIndex++;

      if (currentIndex > currentText.length) {
        clearInterval(typingInterval);
        setTimeout(() => {
          setCurrentHeaderIndex((prevIndex) => (prevIndex + 1) % headerTexts.length);
        }, 2000);
      }
    }, 100);

    return () => clearInterval(typingInterval);
  }, [currentHeaderIndex]);

  return (
    <div className="min-h-screen bg-gradient-to-br from-black to-black text-white">
      <div className="fixed top-0 w-full z-50">
        <NavigationBar isTransparent={true} />
      </div>
      <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-20">
        <section className="flex flex-col items-center justify-center text-center py-20">
          <motion.h1 
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="text-5xl font-extrabold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-orange-400 to-orange-600"
          >
            {typedText}
            <span className="animate-pulse">|</span>
          </motion.h1>
          <motion.p 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
            className="text-xl md:text-2xl mb-10 text-white max-w-3xl"
          >
            Elevate your creative business. Build a stunning profile. Connect with clients. Grow faster than ever.
          </motion.p>
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="bg-orange-600 text-white py-3 px-8 rounded-full text-xl font-semibold shadow-lg hover:bg-orange-700 transition duration-300"
            onClick={() => window.location.href='/onboarding-selection'}
          >
            Get Started Today
          </motion.button>
        </section>

        <motion.section 
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="py-20"
        >
          <h2 className="text-4xl font-bold mb-12 text-center text-white">What's Included in the Challenge?</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            <FeatureCard 
              icon={faUserCircle} 
              title="Build Your Perfect Profile" 
              description="Craft a professional portfolio that reflects your talent in minutes."
            />
            <FeatureCard 
              icon={faFileContract} 
              title="Engage Your Network" 
              description="Reconnect with past clients and turn them into repeat customers."
            />
            <FeatureCard 
              icon={faBriefcase} 
              title="Reach New Clients" 
              description="Use AI-assisted templates to connect with the right clients at the right time."
            />
            <FeatureCard 
              icon={faFileInvoice} 
              title="30-Day Guarantee" 
              description="Get a new client in 30 days or your money back. No questions asked."
            />
          </div>
        </motion.section>

        <motion.section 
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="py-20"
        >
          <h2 className="text-4xl font-bold mb-12 text-center text-white">Full Access to Klorah Tools</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <InfoBox 
              title="Effortless Invoicing" 
              description="Create and send professional invoices in seconds with our AI-powered tools."
            />
            <InfoBox 
              title="Smart Agreements" 
              description="Generate legally-sound agreements quickly and easily."
            />
            <InfoBox 
              title="Freelancer Profiles" 
              description="Showcase your work with a stunning profile to attract new clients."
            />
            <InfoBox 
              title="Bookable Services" 
              description="Allow clients to book your services directly through your profile."
            />
          </div>
        </motion.section>

        <motion.section 
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="py-20"
        >
          <h2 className="text-4xl font-bold mb-12 text-center text-white">Frequently Asked Questions</h2>
          <div className="max-w-3xl mx-auto bg-orange-600 bg-opacity-10 rounded-xl overflow-hidden backdrop-filter backdrop-blur-lg shadow-lg border border-white p-6">
            <FAQItem 
              question="What's included in the Klorah Creator Challenge?"
              answer="The challenge includes a complete toolkit to build your profile, manage client interactions, and run effective outreach campaigns. You'll also get AI-powered templates to streamline your process and save time, along with personalized insights to help grow your creative business."
            />
            <FAQItem 
              question="Do I get access to all Klorah tools during the challenge?"
              answer="Absolutely! During the 30-day challenge, you'll have full access to all Klorah tools, including invoicing, agreement generation, and profile creation features."
            />
            <FAQItem 
              question="What happens if I don't land a client in 30 days?"
              answer="If you don't land a new client after completing the challenge, you can request a full refund. We're confident in the effectiveness of our program and we've got your back."
            />
          </div>
        </motion.section>

        <motion.section 
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="text-center py-20"
        >
          <h2 className="text-4xl font-bold mb-6 text-white">Your Clients Are Waiting</h2>
          <p className="text-xl md:text-2xl mb-10 text-white">Let's get you there in 30 days.</p>
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="bg-orange-600 text-white py-3 px-8 rounded-full text-xl font-semibold shadow-lg hover:bg-orange-700 transition duration-300"
            onClick={() => window.location.href='/onboarding-selection'}
          >
            Join the Klorah Creator Challenge Now
          </motion.button>
        </motion.section>

        <motion.section 
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="py-20"
        >
          <h2 className="text-4xl font-bold mb-12 text-center text-white">Affordable Investment</h2>
          <div className="max-w-3xl mx-auto bg-orange-600 bg-opacity-10 rounded-xl overflow-hidden backdrop-filter backdrop-blur-lg shadow-lg border border-white p-6 text-center">
            <p className="text-xl md:text-2xl mb-4 text-white">
              Join the 30-Day Klorah Creator Challenge for just <span className="font-bold">$15</span>.
            </p>
            <p className="text-lg text-white opacity-80">
              This small investment gives you full access to all our tools and resources, ensuring you have everything you need to succeed. Plus, with our 30-day money-back guarantee, there's absolutely no risk.
            </p>
          </div>
        </motion.section>
      </main>
      <Footer />
    </div>
  );
}
// ThemeModal.js
import React from 'react';

export default function ThemeModal({ isOpen, setIsOpen, invoiceTheme, setInvoiceTheme }) {
  const themes = [
    { id: 'default', name: 'Snow White', primaryColor: '#ffffff', secondaryColor: '#f3f4f6', textColor: '#000000' },
    { id: 'modern', name: 'Minty Fresh', primaryColor: '#e0f2f1', secondaryColor: '#b2dfdb', textColor: '#004d40' },
    { id: 'classic', name: 'Golden Sunrise', primaryColor: '#fff8e1', secondaryColor: '#ffecb3', textColor: '#ff6f00' },
    { id: 'elegant', name: 'Rose Petal', primaryColor: '#fce4ec', secondaryColor: '#f8bbd0', textColor: '#880e4f' },
  ];

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white dark:bg-gray-800 p-8 rounded-lg max-w-2xl w-full">
        <h2 className="text-2xl font-bold mb-4">Select Invoice Theme</h2>
        <div className="grid grid-cols-2 gap-4">
          {themes.map((theme) => (
            <div
              key={theme.id}
              className={`cursor-pointer border rounded-lg p-4 ${
                invoiceTheme === theme.id ? 'border-blue-500' : 'border-gray-200'
              }`}
              onClick={() => {
                setInvoiceTheme(theme.id);
                setIsOpen(false);
              }}
              style={{
                backgroundColor: theme.primaryColor,
                color: theme.textColor,
              }}
            >
              <h3 className="font-bold mb-2">{theme.name}</h3>
              <div style={{ backgroundColor: theme.secondaryColor, height: '20px', marginBottom: '10px' }}></div>
              <p>Sample text</p>
            </div>
          ))}
        </div>
        <button
          onClick={() => setIsOpen(false)}
          className="mt-6 w-full bg-gray-300 text-black py-2 rounded-md hover:bg-gray-400 transition duration-300 dark:bg-gray-600 dark:text-white dark:hover:bg-gray-700"
        >
          Close
        </button>
      </div>
    </div>
  );
}
import React, { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { useNavigate } from 'react-router-dom';
import { getAuth } from "firebase/auth";
import axios from 'axios';

const stripePromise = loadStripe('pk_live_51IuUXbLwpaCHfpZ1EZNNYaQYdXSIrE6NrdA3r7lUQOIq5tsdhC7MaTITIMFb3nW4yxqyppRd8C66QcxWhuoPC8aa00Ow2q0o0z');

export default function Billing() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [logs, setLogs] = useState([]);
  const auth = getAuth();

  const addLog = (message) => {
    setLogs(prevLogs => [...prevLogs, message]);
    console.log(message);
  };

  useEffect(() => {
    async function createStripeCustomer() {
      const userEmail = auth.currentUser?.email;
      const userId = auth.currentUser?.uid;
      if (!userEmail || !userId) {
        addLog("User email or ID is not available");
        return null;
      }

      try {
        addLog(`Creating Stripe customer for email: ${userEmail}`);
        const customerRes = await axios.post('https://klorah-fast-server-9266fe8d441a.herokuapp.com/create-customer', {
          email: userEmail,
        }, {
          headers: {
            Authorization: `Bearer ${await auth.currentUser.getIdToken()}`,
          },
        });

        const customerData = customerRes.data;
        addLog(`Stripe customer created/retrieved: ${customerData.stripe_customer_id}`);
        return { stripeCustomerId: customerData.stripe_customer_id, userId };
      } catch (error) {
        addLog(`Error in createStripeCustomer: ${error.response?.data || error.message}`);
        return null;
      }
    }

    async function createCheckoutSession({ stripeCustomerId, userId }) {
      try {
        addLog(`Creating checkout session for user: ${userId}`);
        const sessionRes = await axios.post('https://klorah-fast-server-9266fe8d441a.herokuapp.com/createCheckoutSession', {
          userId,
          stripeCustomerId,
          priceId: 'price_1PW9x9LwpaCHfpZ19riV4D7I',
        });
    
        const sessionData = sessionRes.data;
    
        if (!sessionData.sessionId) {
          throw new Error("Session ID is missing in the response");
        }
    
        addLog(`Checkout session created: ${sessionData.sessionId}`);
        const stripe = await stripePromise;
        const { error } = await stripe.redirectToCheckout({ sessionId: sessionData.sessionId });
        if (error) {
          addLog(`Stripe Checkout error: ${error.message}`);
        } else {
          localStorage.setItem('isOnboardingComplete', 'true');
          addLog('Redirecting to Stripe Checkout');
        }
      } catch (error) {
        addLog(`Error in createCheckoutSession: ${error.response?.data || error.message}`);
      }
    }    

    if (auth.currentUser) {
      createStripeCustomer().then(data => {
        if (data) {
          createCheckoutSession(data);
        }
      });
    }
  }, [navigate]);

  return (
    <div className="p-5">
      {loading ? (
        <p>Loading...</p>
      ) : (
        <div>
          <img
            className="h-8 w-auto"
            src="/klorahLogo.png"
            alt="Klorah"
            onClick={() => navigate('/')}
          />
          <div className="bg-red-100 text-red-700 p-5 border border-red-400 rounded mt-5">
            <p>There was an error. It might be due to an active ad blocker.</p>
            <p>Please disable it and try again. If the error persists, contact us at:</p>
            <p><a href="mailto:support@klorah.zendesk.com" className="text-blue-500">support@klorah.zendesk.com</a></p>
            <p>or call us at: <strong>+1 (856) 699-6475</strong></p>
          </div>
          <button className="bg-black text-white px-4 py-2 rounded mt-4" onClick={() => navigate('/dashboard')}>
            Navigate to dashboard with free account
          </button>
          <div className="mt-4">
            <h3>Logs:</h3>
            <pre className="bg-gray-100 p-2 rounded">
              {logs.join('\n')}
            </pre>
          </div>
        </div>
      )}
    </div>
  );
}
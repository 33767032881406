import React, { useState, useRef, useEffect } from 'react';

const PortfolioItem = ({ item, openModal }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const mediaRef = useRef(null);

  const isVideo = item.url && /\.(mp4|mov|webm)$/i.test(item.url);

  useEffect(() => {
    setIsLoading(true);
    setHasError(false);
  }, [item]);

  const handleMediaLoad = () => {
    console.log('Media loaded successfully:', item.url || item.image);
    setIsLoading(false);
  };

  const handleMediaError = () => {
    console.error('Failed to load media:', item.url || item.image);
    setHasError(true);
    setIsLoading(false);
  };

  const renderMedia = () => {
    if (isVideo) {
      return (
        <video 
          ref={mediaRef}
          className="w-full h-full object-cover"
          muted 
          loop 
          playsInline
          preload="metadata"
          onCanPlay={handleMediaLoad}
          onError={handleMediaError}
        >
          <source src={item.url} type={`video/${item.url.split('.').pop().toLowerCase()}`} />
          Your browser does not support the video tag.
        </video>
      );
    } else {
      return (
        <img 
          src={item.image} 
          alt={item.title} 
          className="w-full h-full object-cover"
          onLoad={handleMediaLoad}
          onError={handleMediaError}
        />
      );
    }
  };

  return (
    <div 
      className="bg-white dark:bg-gray-800 rounded-lg shadow-md overflow-hidden cursor-pointer transform transition duration-300 hover:scale-105"
      onClick={() => !hasError && openModal(item)}
    >
      <div className="relative w-full h-48">
        {isLoading && (
          <div className="absolute inset-0 flex items-center justify-center bg-gray-200 dark:bg-gray-700">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900 dark:border-gray-100"></div>
          </div>
        )}
        {hasError && (
          <div className="absolute inset-0 flex flex-col items-center justify-center bg-gray-200 dark:bg-gray-700 text-gray-500 dark:text-gray-400 p-4 text-center">
            <p className="mb-2">This content may not be supported in your current browser.</p>
            <p className="mt-2 text-sm">Try viewing in Safari or refresh the page</p>
          </div>
        )}
        {!hasError && renderMedia()}
      </div>
      <div className="p-4">
        <h4 className="text-lg font-bold text-gray-800 dark:text-gray-300 mb-2">{item.title}</h4>
        <p className="text-gray-600 dark:text-gray-400">{item.description}</p>
      </div>
    </div>
  );
};

const Portfolio = ({ portfolio }) => {
  const [selectedItem, setSelectedItem] = useState(null);

  const openModal = (item) => {
    setSelectedItem(item);
  };

  const closeModal = () => {
    setSelectedItem(null);
  };

  return (
    <>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 w-full">
        {portfolio?.map((item, index) => (
          <PortfolioItem key={index} item={item} openModal={openModal} />
        ))}
      </div>

      {selectedItem && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center z-50" onClick={closeModal}>
          <div className="relative max-w-3xl max-h-full p-4 bg-white dark:bg-gray-800 rounded-lg" onClick={(e) => e.stopPropagation()}>
            <button 
              className="absolute top-2 right-2 px-4 py-2 bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-300 rounded hover:bg-gray-300 dark:hover:bg-gray-600 transition duration-300"
              onClick={closeModal}
            >
              Close
            </button>
            {selectedItem.url && (selectedItem.url.toLowerCase().endsWith('.mp4') || selectedItem.url.toLowerCase().endsWith('.mov') || selectedItem.url.toLowerCase().endsWith('.webm')) ? (
              <video 
                src={selectedItem.url} 
                className="w-full h-auto max-h-[80vh] object-contain mb-4" 
                controls 
                autoPlay 
                loop
              >
                Your browser does not support the video tag.
              </video>
            ) : (
              <img 
                src={selectedItem.image} 
                alt={selectedItem.title} 
                className="w-full h-auto max-h-[80vh] object-contain mb-4" 
              />
            )}
            <h3 className="text-xl font-bold text-gray-800 dark:text-gray-300 mb-2">{selectedItem.title}</h3>
            <p className="text-gray-600 dark:text-gray-400 mb-4">{selectedItem.description}</p>
          </div>
        </div>
      )}
    </>
  );
};

export default Portfolio;
import React, { useState, useEffect } from 'react';
import { getAuth, createUserWithEmailAndPassword, sendEmailVerification, onAuthStateChanged, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import app from '../Firebase';
import { db } from '../Firebase';
import { doc, setDoc } from 'firebase/firestore';
import mixpanel from 'mixpanel-browser';
import ReCAPTCHA from 'react-google-recaptcha';

export default function SignUp() {
  const auth = getAuth(app);
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');
  const [captchaValue, setCaptchaValue] = useState(null);
  const [isSent, setIsSent] = useState(false);
  const [verificationMessage, setVerificationMessage] = useState('');

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        navigate('/onboarding-questions'); // Move to onboarding questions after sign up
      }
    });

    return () => unsubscribe();
  }, [auth]);

  const onSendEmailVerification = () => {
    sendEmailVerification(auth.currentUser).then(() => setIsSent(true));
  };

  const handleGoogleSignUp = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      await updateUsernameInDatabase(user.uid, user.displayName);
      mixpanel.track('User Sign Up with Google', { 'User ID': user.uid, Email: user.email, Username: user.displayName });
      navigate('/onboarding-questions');
    } catch (error) {
      const errorMessage = error.message;
      setErrorMessage(errorMessage);
      mixpanel.track('Google Sign Up Error', { 'Error Message': errorMessage });
    }
  };

  async function handleSubmit(event) {
    event.preventDefault();
    const email = event.target.email.value;
    const password = event.target.password.value;
    const username = event.target.username.value;

    if (!captchaValue) {
      setErrorMessage('Please verify the captcha');
      return;
    }

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      await sendEmailVerification(user);
      await updateUsernameInDatabase(user.uid, username);
      mixpanel.track('User Sign Up', { 'User ID': user.uid, Email: email, Username: username });
      setVerificationMessage('Verification email sent. Please check your inbox.');
      navigate('/onboarding-questions');
    } catch (error) {
      const errorMessage = error.message;
      setErrorMessage(errorMessage);
      mixpanel.track('Sign Up Error', { 'Error Message': errorMessage });
    }
  }

  async function updateUsernameInDatabase(userId, username) {
    const userRef = doc(db, 'users', userId);
    try {
      await setDoc(userRef, {
        username,
        userId,
        isPro: false,
        invoicesCreated: 0,
        agreementsCreated: 0,
        stripeOnboarded: false,
      });
      mixpanel.track('Username Update', { 'User ID': userId, Username: username });
    } catch (error) {
      console.error('Error updating username in database:', error);
      mixpanel.track('Username Update Error', { 'Error Message': error.message });
    }
  }

  return (
    <div className="flex min-h-screen items-center justify-center bg-gradient-to-r from-orange-400 via-orange-500 to-orange-600 py-12 px-4 sm:px-6 lg:px-8">
      <div className="w-full max-w-md space-y-8 bg-white dark:bg-gray-800 rounded-2xl p-8 shadow-lg text-black dark:text-gray-200">
        <div className="text-center">
          <a href="/">
            <img className="mx-auto h-12 w-auto" src="/klorahLogo.png" alt="Logo" />
          </a>
          <h2 className="mt-6 text-3xl font-extrabold text-gray-900 dark:text-white">
            Create your account
          </h2>
          <p className="mt-2 text-sm text-gray-600 dark:text-gray-400">
            Or{' '}
            <a href="/login" className="font-medium text-orange-600 hover:text-orange-500">
              login to your account
            </a>
          </p>
        </div>
        <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
          <input type="hidden" name="remember" defaultValue="true" />
          <div className="rounded-md shadow-sm -space-y-px">
            <div>
              <label htmlFor="email" className="sr-only">
                Email address
              </label>
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-black dark:focus:ring-white focus:border-black dark:focus:border-white focus:z-10 sm:text-sm"
                placeholder="Email address"
              />
            </div>
            <div>
              <label htmlFor="username" className="sr-only">
                Username
              </label>
              <input
                id="username"
                name="username"
                type="text"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-black dark:focus:ring-white focus:border-black dark:focus:border-white focus:z-10 sm:text-sm"
                placeholder="Username"
              />
            </div>
            <div>
              <label htmlFor="password" className="sr-only">
                Password
              </label>
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-black dark:focus:ring-white focus:border-black dark:focus:border-white focus:z-10 sm:text-sm"
                placeholder="Password"
              />
            </div>
          </div>

          <div className="mt-4 flex justify-center">
            <ReCAPTCHA sitekey="6LfG2bIpAAAAAJrXdAnO_tOYO9OYmpQOHreiCr0i" onChange={(value) => setCaptchaValue(value)} />
          </div>

          <div className="mt-4">
            <button
              type="submit"
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-black dark:bg-white dark:text-black hover:bg-gray-700 dark:hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black dark:focus:ring-white"
            >
              Sign up
            </button>
          </div>
        </form>
        {errorMessage && <div className="text-center text-sm text-red-600">{errorMessage}</div>}
        {verificationMessage && <div className="text-center text-sm text-green-600">{verificationMessage}</div>}
        <div className="mt-4">
          <button
            onClick={handleGoogleSignUp}
            className="w-full flex justify-center py-2 px-4 border border-gray-300 rounded-md text-sm font-medium text-gray-700 bg-white hover:bg-gray-100"
          >
            <img src="/googleIcon.svg" alt="Google logo" className="h-5 w-5 mr-2" />
            Sign up with Google
          </button>
        </div>
        <div className="mt-4 flex justify-between">
          <button type="button" onClick={() => navigate('/tos')} className="text-gray-600 dark:text-gray-400 hover:text-gray-500">
            Terms of Service
          </button>
          <button type="button" onClick={() => navigate('/privacy-policy')} className="text-gray-600 dark:text-gray-400 hover:text-gray-500">
            Privacy Policy
          </button>
        </div>
      </div>
    </div>
  );
}

// ProModal.js
import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function ProModal({ setShowProModal }) {
  const navigate = useNavigate();

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white dark:bg-gray-800 p-8 rounded-lg max-w-md w-full">
        <h2 className="text-2xl font-bold mb-4">Upgrade to Pro</h2>
        <p className="mb-4">Enjoy lower transaction fees and more features with our Pro plan:</p>
        <ul className="list-disc list-inside mb-4">
          <li>2.9% transaction fee instead of 3.3%</li>
          <li>Custom email templates</li>
          <li>Advanced reporting</li>
        </ul>
        <button
          onClick={() => navigate('/subscription')}
          className="w-full bg-black text-white dark:bg-white dark:text-black py-2 rounded-md hover:bg-gray-800 dark:hover:bg-gray-200"
        >
          Upgrade to Pro
        </button>
        <button
          onClick={() => setShowProModal(false)}
          className="w-full mt-2 bg-gray-200 text-black dark:bg-gray-600 dark:text-white py-2 rounded-md hover:bg-gray-300 dark:hover:bg-gray-700"
        >
          Close
        </button>
      </div>
    </div>
  );
}
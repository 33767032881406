import React from 'react';
import { useNavigate } from 'react-router-dom';

function TermsOfService() {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  return (
    <div className="flex flex-col items-center justify-center p-10 min-h-screen bg-gray-100 dark:bg-gray-900">
      <div className="w-full max-w-xl bg-white dark:bg-gray-800 p-10 rounded-lg shadow-md">
        <button onClick={goBack} className="text-blue-500 hover:underline dark:text-blue-300">Back</button>
        <h1 className="text-2xl font-bold mb-5 text-center dark:text-white">Terms of Service</h1>
        <p className="mb-5 text-center dark:text-gray-300"><strong>Last Updated: October 14, 2023</strong></p>
        <h2 className="text-xl font-bold mb-3 dark:text-white">Introduction</h2>
        <p className="mb-5 dark:text-gray-300">Welcome to Klorah. These Terms of Service govern your use of our service.</p>
        <h2 className="text-xl font-bold mb-3 dark:text-white">Acceptance of Terms</h2>
        <p className="mb-5 dark:text-gray-300">By using Klorah, you agree to these Terms.</p>
        <h2 className="text-xl font-bold mb-3 dark:text-white">Changes to Terms</h2>
        <p className="mb-5 dark:text-gray-300">Klorah reserves the right to change these terms at any time.</p>
        <h2 className="text-xl font-bold mb-3 dark:text-white">Usage Restrictions</h2>
        <p className="mb-5 dark:text-gray-300">You may not use Klorah for any illegal activities.</p>
        <h2 className="text-xl font-bold mb-3 dark:text-white">Termination</h2>
        <p className="mb-5 dark:text-gray-300">Klorah reserves the right to terminate your access to the service.</p>
        <h2 className="text-xl font-bold mb-3 dark:text-white">Governing Law</h2>
        <p className="dark:text-gray-300">These Terms are governed by the laws of the United States.</p>
      </div>
    </div>
  );
}

export default TermsOfService;

import React from 'react';
import { CheckCircle2, Circle, AlertCircle, ArrowRight } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const ProfileSuggestions = ({ profile }) => {
  const navigate = useNavigate();

  if (!profile) return null;

  const suggestions = [
    { 
      id: 1, 
      text: "Add a professional profile picture", 
      check: profile => !!profile.profilePicture,
      importance: "high"
    },
    { 
      id: 2, 
      text: "Write a compelling bio (at least 100 characters)", 
      check: profile => profile.bio && profile.bio.length >= 100,
      importance: "high"
    },
    { 
      id: 3, 
      text: "List at least 5 skills", 
      check: profile => profile.skills && profile.skills.length >= 5,
      importance: "medium"
    },
    { 
      id: 4, 
      text: "Add at least one service", 
      check: profile => profile.services && profile.services.length > 0,
      importance: "high"
    },
    { 
      id: 5, 
      text: "Include your professional title", 
      check: profile => !!profile.professionalTitle,
      importance: "medium"
    },
    { 
      id: 6, 
      text: "Add contact information (email and phone)", 
      check: profile => profile.contactInfo && profile.contactInfo.email && profile.contactInfo.phone,
      importance: "high"
    },
    { 
      id: 7, 
      text: "Link your social media profiles", 
      check: profile => profile.socialMediaLinks && Object.values(profile.socialMediaLinks).some(link => !!link),
      importance: "medium"
    },
    { 
      id: 8, 
      text: "Add portfolio items", 
      check: profile => profile.portfolio && profile.portfolio.length > 0,
      importance: "high"
    },
    {
      id: 9,
      text: "Set your location",
      check: profile => profile.contactInfo && !!profile.contactInfo.location,
      importance: "low"
    },
    {
      id: 10,
      text: "Add your personal website",
      check: profile => !!profile.personalWebsite,
      importance: "low"
    }
  ];

  const getImportanceIcon = (importance) => {
    switch(importance) {
      case "high":
        return <AlertCircle className="h-4 w-4 text-red-500 ml-2" />;
      case "medium":
        return <AlertCircle className="h-4 w-4 text-yellow-500 ml-2" />;
      default:
        return null;
    }
  };

  return (
    <div className="bg-gray-900 rounded-xl shadow-xl p-6 mt-8">
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-xl font-semibold text-orange-400">Profile Enhancement Suggestions</h3>
        <button 
          onClick={() => navigate('/edit-profile')} 
          className="text-orange-400 hover:text-orange-300 transition-colors"
        >
          <ArrowRight className="h-6 w-6" />
        </button>
      </div>
      {suggestions.map(suggestion => (
        <div key={suggestion.id} className="flex items-center mb-2">
          {suggestion.check(profile) ? (
            <CheckCircle2 className="h-5 w-5 text-green-500 mr-2 flex-shrink-0" />
          ) : (
            <Circle className="h-5 w-5 text-orange-500 mr-2 flex-shrink-0" />
          )}
          <span className={`text-sm ${suggestion.check(profile) ? 'text-gray-400' : 'text-white'}`}>
            {suggestion.text}
          </span>
          {!suggestion.check(profile) && getImportanceIcon(suggestion.importance)}
        </div>
      ))}
    </div>
  );
};

export default ProfileSuggestions;
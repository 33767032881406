import React, { useState, useEffect } from 'react';
import { 
  motion, 
  useScroll, 
  useTransform, 
  useMotionValue, 
  useVelocity, 
  useSpring, 
  useAnimationFrame 
} from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileInvoice, faFileContract, faUserCircle, faBriefcase, faRocket } from '@fortawesome/free-solid-svg-icons';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import NavigationBar from './NavigationBar';
import Footer from './Footer';
import { ArrowRight } from "lucide-react";

const headerTexts = [
  'Simplify Your Client Billing',
  'Empower Your Freelance Career',
  'Streamline Your Workflow',
  'Join the Freelance Revolution',
];

const wrap = (min, max, v) => {
  const rangeSize = max - min;
  return ((((v - min) % rangeSize) + rangeSize) % rangeSize) + min;
};

const ParallaxContainer = ({ children, baseVelocity = 20 }) => {
  const baseX = useMotionValue(0);
  const { scrollY } = useScroll();
  const scrollVelocity = useVelocity(scrollY);
  const smoothVelocity = useSpring(scrollVelocity, {
    damping: 50,
    stiffness: 400
  });
  const velocityFactor = useTransform(smoothVelocity, [0, 1000], [0, 5], {
    clamp: false
  });

  const x = useTransform(baseX, (v) => `${wrap(-100, 0, v)}%`);

  useAnimationFrame((t, delta) => {
    let moveBy = baseVelocity * (delta / 1000);
    baseX.set(baseX.get() + moveBy);
  });

  return (
    <div className="parallax-container overflow-hidden whitespace-nowrap">
      <motion.div className="parallax-scroller" style={{ x }}>
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
      </motion.div>
    </div>
  );
};

const FeatureCard = ({ icon, title, description }) => {
  return (
    <motion.div 
      whileHover={{ scale: 1.05 }}
      className="bg-white dark:bg-[#161315] p-6 rounded-lg shadow-md dark:shadow-lg dark:shadow-[#00000080]"
    >
      <FontAwesomeIcon icon={icon} size="2x" className="mb-4 text-[#161315] dark:text-white" />
      <h4 className="text-xl font-bold mb-2 text-[#161315] dark:text-white">{title}</h4>
      <p className="text-[#161315] dark:text-white">{description}</p>
    </motion.div>
  );
};

export default function LandingPage() {
  const [currentHeaderIndex, setCurrentHeaderIndex] = useState(0);
  const [typedText, setTypedText] = useState('');
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    let currentIndex = 0;
    const currentText = headerTexts[currentHeaderIndex];

    const typingInterval = setInterval(() => {
      setTypedText(currentText.slice(0, currentIndex));
      currentIndex++;

      if (currentIndex > currentText.length) {
        clearInterval(typingInterval);
        setTimeout(() => {
          setCurrentHeaderIndex((prevIndex) => (prevIndex + 1) % headerTexts.length);
        }, 2000);
      }
    }, 100);

    return () => clearInterval(typingInterval);
  }, [currentHeaderIndex]);

  return (
    <div className="min-h-screen text-[#161315] dark:text-white bg-white dark:bg-[#161315]">
      <div className="sticky top-0 z-50">
        <NavigationBar isTransparent={document.documentElement.classList.contains('dark')} />
      </div>
      
      <main className="container mx-auto px-4">
        <section className="flex flex-col items-center justify-center text-center py-24 bg-white dark:bg-[#161315]" style={{ minHeight: '80vh' }}>
          <div className="h-[120px] flex items-center justify-center mb-6">
            <h1 className="text-5xl md:text-7xl font-bold text-[#161315] dark:text-white">
              {typedText}
              <span className="animate-pulse">|</span>
            </h1>
          </div>
          <p className="text-2xl mb-8 text-[#161315] dark:text-white">Your new client billing solution powered by AI</p>
          <motion.div
            className="relative"
            onHoverStart={() => setIsHovered(true)}
            onHoverEnd={() => setIsHovered(false)}
          >
            <motion.div
              className="absolute inset-0 bg-gradient-to-br from-[#CBFF5B] to-[#E8871E] rounded-lg shadow-lg dark:shadow-xl dark:shadow-[#00000080]"
              initial={false}
              animate={isHovered ? { scale: 1.05, rotate: -3, y: -5 } : { scale: 1, rotate: 0, y: 0 }}
              transition={{ type: 'spring', stiffness: 500, damping: 30 }}
            />
            <motion.button
              className="relative px-8 py-4 bg-[#CBFF5B] text-[#161315] dark:bg-[#E8871E] dark:text-white font-bold text-lg rounded-lg shadow-lg dark:shadow-xl dark:shadow-[#00000080] border-b-4 border-[#CBFF5B] dark:border-[#E8871E]"
              whileHover={{ scale: 1.05, rotate: 3, y: -5 }}
              whileTap={{ scale: 0.95, y: 0, borderBottomWidth: '2px' }}
              transition={{ type: 'spring', stiffness: 500, damping: 30 }}
              onClick={() => window.location.href='/onboarding-selection'}
            >
              <span className="mr-2">Get Started</span>
              <motion.span
                initial={{ x: 0 }}
                animate={isHovered ? { x: 5 } : { x: 0 }}
                transition={{ type: 'spring', stiffness: 500, damping: 30 }}
              >
                <ArrowRight className="inline-block h-5 w-5" />
              </motion.span>
            </motion.button>
          </motion.div>
          <motion.p
            className="mt-2 text-sm text-[#161315] dark:text-white font-medium"
            initial={{ opacity: 0, y: 5 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 }}
          >
            No credit card needed
          </motion.p>
          <div className="mt-12 w-full max-w-[1200px] mx-auto">
            <div className="relative" style={{ paddingBottom: '56.25%' }}>
              <img 
                src="/dashboard-image.jpg"
                alt="Dashboard Image" 
                className="absolute top-0 left-0 w-full h-full rounded-lg shadow-lg dark:shadow-xl dark:shadow-[#00000080]"
              />
            </div>
          </div>
        </section>

        <ParallaxContainer>Design • Create • Inspire • Thrive • Design • Create • Inspire • Thrive • Design • Create • Inspire • Thrive • Design • Create • Inspire • Thrive</ParallaxContainer>

        <motion.section 
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="py-24"
        >
          <h2 className="text-4xl font-bold mb-16 text-center text-[#161315] dark:text-white">Why Choose Klorah?</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-12">
            <FeatureCard 
              icon={faFileInvoice} 
              title="Effortless Invoicing" 
              description="Create and send professional invoices in seconds."
            />
            <FeatureCard 
              icon={faFileContract} 
              title="Smart Agreements" 
              description="Generate legally-sound agreements quickly with our AI tools."
            />
            <FeatureCard 
              icon={faUserCircle} 
              title="Freelancer Profiles" 
              description="Showcase your work with a stunning profile and attract new clients."
            />
            <FeatureCard 
              icon={faBriefcase} 
              title="Bookable Services" 
              description="Allow clients to book your services directly through your profile."
            />
          </div>
        </motion.section>
           
        <motion.section
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="py-24 text-[#161315] dark:text-white rounded-xl my-20 shadow-2xl dark:shadow-3xl dark:shadow-[#00000080] overflow-hidden"
        >
          <div className="container mx-auto px-6">
            <h2 className="text-4xl md:text-5xl font-extrabold mb-12 text-center">Klorah Creator Challenge</h2>
            <div className="max-w-3xl mx-auto space-y-8">
              <h3 className="text-3xl md:text-4xl font-bold">30 Days to Transform Your Career</h3>
              <p className="text-lg leading-relaxed">
                Join our intensive 30-day program designed to kickstart your freelance career. Get clients in 30 days or your money back!
              </p>
              <ul className="space-y-4">
                {[
                  { icon: faRocket, text: "Daily tasks and expert guidance" },
                  { icon: faUserCircle, text: "Build a standout profile" },
                  { icon: faBriefcase, text: "Connect with potential clients" },
                  { icon: faFileInvoice, text: "Full access to all Klorah tools" }
                ].map((item, index) => (
                  <li key={index} className="flex items-center text-lg">
                    <FontAwesomeIcon icon={item.icon} className="mr-3 text-2xl text-[#E8871E]" />
                    {item.text}
                  </li>
                ))}
              </ul>
              <div className="flex justify-center">
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  className="bg-[#E8871E] text-white py-3 px-8 rounded-md text-lg font-semibold shadow-lg dark:shadow-xl dark:shadow-[#00000080] transition-transform duration-300 hover:bg-[#BA3B46]"
                  onClick={() => window.location.href='/creator-challenge'}
                >
                  Learn More
                </motion.button>
              </div>
            </div>
          </div>
        </motion.section>

        <motion.section 
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="py-24 bg-white dark:bg-[#161315]"
        >
          <h2 className="text-4xl font-bold mb-16 text-center text-[#161315] dark:text-white">Pricing</h2>
          <div className="flex flex-wrap justify-center items-center gap-12">
            <div className="flex flex-col bg-[#F5FAEF] dark:bg-[#1E1E1E] p-8 rounded-lg shadow-md dark:shadow-lg dark:shadow-[#00000080] w-full sm:w-1/2 md:w-1/3">
              <h3 className="text-3xl font-bold mb-4 text-center text-[#161315] dark:text-white">Free Plan</h3>
              <h4 className="text-xl font-medium mb-2 text-center text-[#161315] dark:text-white">$0/month</h4>
              <p className="text-center text-[#161315] dark:text-white mb-6">3.3% transaction fee on invoices</p>
              <ul className="space-y-3 text-lg mb-8">
                <li className="flex items-center text-[#161315] dark:text-white"><span className="mr-2 text-[#E8871E]">✓</span>Unlimited access to Klorah community</li>
                <li className="flex items-center text-[#161315] dark:text-white"><span className="mr-2 text-[#E8871E]">✓</span>Basic AI agreements</li>
                <li className="flex items-center text-[#161315] dark:text-white"><span className="mr-2 text-[#E8871E]">✓</span>Customer support</li>
                <li className="flex items-center text-[#161315] dark:text-white"><span className="mr-2 text-[#E8871E]">✓</span>Access to new features and updates</li>
              </ul>
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="mt-auto bg-[#CBFF5B] text-[#161315] dark:text-white py-3 px-6 rounded-lg font-bold hover:bg-[#E8871E] transition-colors shadow-lg dark:shadow-xl dark:shadow-[#00000080]"
                onClick={() => window.location.href='/onboarding-selection'}
              >
                Get Started Now
              </motion.button>
            </div>
            <div className="flex flex-col bg-[#F5FAEF] dark:bg-[#1E1E1E] p-8 rounded-lg shadow-md dark:shadow-lg dark:shadow-[#00000080] border-2 border-[#CBFF5B] w-full sm:w-1/2 md:w-1/3">
              <h3 className="text-3xl font-bold mb-4 text-center text-[#161315] dark:text-white">Pro Plan</h3>
              <h4 className="text-xl font-medium mb-2 text-center text-[#161315] dark:text-white">$15/month</h4>
              <p className="text-center text-[#161315] dark:text-white mb-6">2.9% transaction fee on invoices</p>
              <ul className="space-y-3 text-lg mb-8">
                <li className="flex items-center text-[#161315] dark:text-white"><span className="mr-2 text-[#E8871E]">✓</span>Cutting-edge invoicing capabilities</li>
                <li className="flex items-center text-[#161315] dark:text-white"><span className="mr-2 text-[#E8871E]">✓</span>AI-generated or seamlessly imported client agreements</li>
                <li className="flex items-center text-[#161315] dark:text-white"><span className="mr-2 text-[#E8871E]">✓</span>Exclusive professional-grade tools</li>
                <li className="flex items-center text-[#161315] dark:text-white"><span className="mr-2 text-[#E8871E]">✓</span>Early access to new products and features</li>
              </ul>
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="mt-auto bg-[#CBFF5B] text-[#161315] dark:text-white py-3 px-6 rounded-lg font-bold hover:bg-[#E8871E] transition-colors shadow-lg dark:shadow-xl dark:shadow-[#00000080]"
                onClick={() => window.location.href='/onboarding-selection'}
              >
                Get Started Now
              </motion.button>
            </div>
          </div>
        </motion.section>

        <motion.section 
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="text-center py-24"
        >
          <h2 className="text-4xl font-bold mb-6 text-[#161315] dark:text-white">Join the Freelance Revolution</h2>
          <p className="text-2xl mb-12 text-[#161315] dark:text-white max-w-2xl mx-auto">Klorah isn't just a tool—it's a community of freelancers changing the way they work. Join us and experience financial freedom.</p>
          <motion.button
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.95 }}
            className="bg-[#E8871E] text-white py-3 px-6 rounded-lg text-xl font-bold hover:bg-[#BA3B46] transition-colors shadow-lg dark:shadow-xl dark:shadow-[#00000080]"
            onClick={() => window.location.href='/onboarding-selection'}
          >
            Sign Up for Free
          </motion.button>
        </motion.section>
      </main>
      <Footer />
    </div>
  );
}
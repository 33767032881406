// CreateInvoice.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getFirestore, doc, getDoc, onSnapshot, collection } from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import NavigationBar from '../../NavigationBar';
import LoadingPage from '../Agreement/loadingPage';
import { VerificationBanner } from '../VerificationBanner';
import DarkModeButton from '../../DarkModeButton';
import InvoiceForm from './InvoiceForm';
import InvoicePreview from './InvoicePreview';
import ThemeModal from './ThemeModal';
import ProModal from './ProModal';

export default function CreateInvoice() {
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isProUser, setIsProUser] = useState(false);
  const [showProModal, setShowProModal] = useState(false);
  const [isThemeModalOpen, setIsThemeModalOpen] = useState(false);
  const [invoiceTheme, setInvoiceTheme] = useState('default');
  const [invoiceData, setInvoiceData] = useState({
    gig: '',
    customer: '',
    currency: 'USD',
    price: '',
    dueDate: new Date(),
    userEmail: '',
    customEmailSubject: '',
    customEmailContent: '',
  });
  const [showInvoicePreview, setShowInvoicePreview] = useState(false); // Defined the undefined

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setCurrentUser(user);
        fetchUserProStatus(user.uid);
      } else {
        console.error('No user is logged in.');
        navigate('/login');
      }
    });
    return () => unsubscribe();
  }, [navigate]);

  useEffect(() => {
    if (currentUser) {
      setLoading(false);
    }
  }, [currentUser]);

  const fetchUserProStatus = async (userId) => {
    const firestore = getFirestore();
    const docRef = doc(firestore, 'users', userId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const userData = docSnap.data();
      setIsProUser(userData.isPro || false);
    } else {
      console.error('No such document!');
    }
  };

  if (loading) {
    return <LoadingPage />;
  }

  if (!currentUser || !currentUser.emailVerified) {
    return (
      <div className="text-black dark:text-white min-h-screen">
        <NavigationBar />
        <div className="mx-auto max-w-7xl px-4 py-24 sm:px-6 lg:px-8">
          <div className="text-center">
            <p className="text-lg text-red-600">You must verify your email to create invoices.</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white dark:bg-black text-black dark:text-white min-h-screen">
      <NavigationBar />
      <DarkModeButton />
      <div className="mx-auto max-w-7xl px-4 pb-24 pt-16 sm:px-6 lg:px-8">
        <form className="lg:grid lg:grid-cols-2 lg:gap-x-14 xl:gap-x-16">
          <div className="lg:block hidden">
            <InvoicePreview 
              invoiceData={invoiceData} 
              invoiceTheme={invoiceTheme} 
              setIsThemeModalOpen={setIsThemeModalOpen}
            />
          </div>
          <div className="lg:hidden">
            <button
              type="button"
              onClick={() => setShowInvoicePreview(!showInvoicePreview)}
              className="my-6 w-full bg-gray-200 text-black py-3 rounded-md hover:bg-gray-300 transition duration-300 dark:bg-gray-700 dark:text-white dark:hover:bg-gray-600"
            >
              {showInvoicePreview ? 'Hide Invoice Preview' : 'Show Invoice Preview'}
            </button>
            {showInvoicePreview && (
              <InvoicePreview 
                invoiceData={invoiceData} 
                invoiceTheme={invoiceTheme} 
                setIsThemeModalOpen={setIsThemeModalOpen}
              />
            )}
          </div>
          <div className="flex justify-center w-full">
            <InvoiceForm 
              invoiceData={invoiceData} 
              setInvoiceData={setInvoiceData}
              currentUser={currentUser}
              isProUser={isProUser}
              setShowProModal={setShowProModal}
            />
          </div>
        </form>
      </div>
      <VerificationBanner />
      {showProModal && <ProModal setShowProModal={setShowProModal} />}
      <ThemeModal 
        isOpen={isThemeModalOpen} 
        setIsOpen={setIsThemeModalOpen}
        invoiceTheme={invoiceTheme}
        setInvoiceTheme={setInvoiceTheme}
      />
    </div>
  );
}
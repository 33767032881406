import React from 'react';

const ContactInfo = ({ profile }) => {
  return (
    <div className="bg-gray-100 dark:bg-gray-800 p-4 rounded-lg shadow-md">
      <h3 className="text-lg font-bold text-gray-800 dark:text-gray-300 mb-4">Contact</h3>
      <p className="text-gray-600 dark:text-gray-400">Email: {profile.email}</p>
      <p className="text-gray-600 dark:text-gray-400">Phone: {profile.phone}</p>
      <p className="text-gray-600 dark:text-gray-400">Location: {profile.location}</p>
      <a href={profile.personalWebsite} target="_blank" rel="noopener noreferrer" className="text-blue-500 dark:text-blue-400 hover:underline mt-2 block">Personal Website</a>
    </div>
  );
};

export default ContactInfo;
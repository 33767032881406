import React from 'react';
import { useNavigate } from 'react-router-dom';
import { CheckIcon } from '@heroicons/react/24/solid';
import { useOnboarding } from './OnboardingContext';
import { doc, getDoc } from 'firebase/firestore';
import { db, auth } from '../Firebase';

const includedFeaturesPro = [
  'Cutting-edge invoicing capabilities',
  'AI-generated or seamlessly imported client agreements',
  'Exclusive professional-grade tools',
  'Early access to new products and features'
];

export { includedFeaturesPro };

export default function MembershipBilling() {
  const { isOnboardingComplete, updateOnboardingStatus, isLoading, user } = useOnboarding();
  const navigate = useNavigate();

  const handleProOnboarding = async () => {
    try {
      await updateOnboardingStatus(true);
      navigate('/billing');
    } catch (error) {
      console.error('Failed to update onboarding status:', error);
      // Optionally, show an error message to the user
    }
  };

  const handleCompleteOnboarding = async () => {
    try {
      await updateOnboardingStatus(true);
      navigate('/dashboard');
    } catch (error) {
      console.error('Error updating onboarding status:', error);
      // Optionally, show an error message to the user
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!user) {
    return <div>Please log in to continue</div>;
  }

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-r from-orange-400 via-orange-700 to-orange-600 text-white p-6">
      <div className="max-w-4xl">
        <div className="text-center mb-12">
          <h2 className="text-4xl font-extrabold">Would you like a Pro account?</h2>
          <p className="mt-4 text-xl">Unlock exclusive features and elevate your experience with Klorah Pro!</p>
        </div>
        <div className="flex flex-col gap-8">
          {/* Pro Plan Section */}
          <div className="rounded-3xl bg-black py-10 px-8 text-white shadow-lg shadow-gray-500/50">
            <h3 className="text-3xl font-bold mb-4">Klorah Pro Plan</h3>
            <p className="text-2xl font-semibold mb-4">$15 / month</p>
            <p className="text-lg mb-6 text-gray-400">Try it free for 14 days</p>
            <ul className="mb-6 space-y-4 text-lg">
              {includedFeaturesPro.map((feature) => (
                <li key={feature} className="flex items-center">
                  <CheckIcon className="h-6 w-6 text-green-500 mr-2" aria-hidden="true" />
                  <span>{feature}</span>
                </li>
              ))}
            </ul>
            <button
              className="w-full py-3 px-4 rounded-lg bg-white text-gray-900 hover:bg-gray-200 transition duration-300"
              onClick={handleProOnboarding}
            >
              Start Free Trial
            </button>
          </div>
          {/* Free Plan Section */}
          <div>
            <button
              className="w-full py-1 px-2 rounded-md text-white hover:bg-gray-200 transition duration-300"
              onClick={handleCompleteOnboarding}
            >
              Continue for Free
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { createClient } from "contentful";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import KlorahBanner from "./KlorahBanner";
import NavigationBar from "../LandingPage.js/NavigationBar";
import Footer from "../LandingPage.js/Footer";

const client = createClient({
  space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
  environment: "master",
  accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
});

// Define custom render options for headings
const options = {
  renderNode: {
    'heading-1': (node) => <h1 className="text-3xl font-bold my-4">{node.content[0].value}</h1>,
    'heading-2': (node) => <h2 className="text-2xl font-semibold my-3">{node.content[0].value}</h2>,
    'heading-3': (node) => <h3 className="text-xl font-medium my-2">{node.content[0].value}</h3>,
    'heading-4': (node) => <h4 className="text-lg font-normal my-1">{node.content[0].value}</h4>,
    // Add more heading levels as needed
  },
};

function LoadingSpinner() {
  return <div className="loading-spinner">Loading...</div>;
}

function ErrorMessage({ message }) {
  return <div className="error-message">{message}</div>;
}

function BackLink() {
  return (
    <Link to="/blog" className="back-link">
      &larr; Back to Blog
    </Link>
  );
}

export default function BlogPost() {
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    if (!id) return;

    client
      .getEntry(id)
      .then((entry) => {
        console.log("Fetched entry:", entry); // Log the fetched entry
        setPost(entry);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching post:", error);
        setError("Failed to load the blog post. Please try again later.");
        setLoading(false);
      });
  }, [id]);

  if (loading) return <LoadingSpinner />;
  if (error) return <ErrorMessage message={error} />;
  if (!post) return <ErrorMessage message="Post not found" />;

  const imageUrl = post.fields.image?.fields?.file?.url;

  return (
    <div className="min-h-screen bg-white dark:bg-[#161315]">
      <div className="sticky top-0 z-50">
        <NavigationBar isTransparent={document.documentElement.classList.contains('dark')} />
      </div>
      <div className="container mx-auto px-4 py-8">
        <div className="m-4">
          <BackLink />
        </div>
        <h1 className="text-4xl font-bold mb-6">{post.fields.title}</h1>
        {imageUrl && (
          <img
            src={`https:${imageUrl}`}
            alt={post.fields.title}
            className="w-full h-auto mb-6 rounded-lg shadow-lg"
          />
        )}
        <div className="prose max-w-none">
          {documentToReactComponents(post.fields.body, options)} {/* Pass the options here */}
        </div>
      </div>
      <KlorahBanner />
      <Footer />
    </div>
  );
}
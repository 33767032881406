import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import NavigationBar from '../NavigationBar';
import useCurrentUser from '../Authentication/currentUser';
import LoadingPage from '../Dashboard/Agreement/loadingPage';
import DarkModeButton from '../DarkModeButton';

const WalletView = () => {
  const navigate = useNavigate();
  const { currentUser } = useCurrentUser();
  const [balance, setBalance] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    if (currentUser) {
      if (!currentUser.onboardingComplete || !currentUser.stripeOnboarded) {
        navigate('/stripe-ob');
      }
    }
  }, [currentUser, navigate]);

  useEffect(() => {
    if (currentUser?.stripeAccountId) {
      fetchBalance(currentUser.stripeAccountId);
    }
  }, [currentUser]);

  const fetchBalance = async (accountId) => {
    setIsLoading(true);
    try {
      setError('');
      const response = await fetch(`https://klorah-fast-server-9266fe8d441a.herokuapp.com/balance/${accountId}`);
      if (!response.ok) {
        throw new Error(`Failed to fetch balance: ${response.statusText}`);
      }
      const data = await response.json();
      setBalance(data);
    } catch (error) {
      console.error('Fetch error:', error);
      setError('Failed to fetch balance. Please try again later.');
    }
    setIsLoading(false);
  };

  const transactions = [
    // Example transactions
    { date: '2022-01-01', amount: 100 },
    { date: '2022-01-02', amount: 200 },
  ];

  if (isLoading) {
    return (
      <div>
      <div style={{ position: 'sticky', top: 0, zIndex: 1000 }}>
        <NavigationBar />
      </div>
        <div className="flex justify-center items-center h-screen">
          <LoadingPage />
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white dark:bg-black text-black dark:text-white min-h-screen">
      <div style={{ position: 'sticky', top: 0, zIndex: 1000 }}>
        <NavigationBar />
      </div>
      <div className="flex flex-col items-center justify-center h-screen px-4 md:px-0">
        <div className="w-full max-w-4xl p-6 border rounded-lg shadow-lg">
          {balance && (
            <div>
              <h2 className="text-2xl font-bold text-center mb-4">Your Balance</h2>
              <p className="text-xl">Available: ${balance.available[0].amount / 100}</p>
              <p className="text-xl">Pending: ${balance.pending[0].amount / 100}</p>
            </div>
          )}
          <button
            className="mt-4 w-full md:w-auto px-4 py-2 text-white bg-black dark:bg-white dark:text-black rounded hover:bg-gray-900 dark:hover:bg-gray-700"
            onClick={() => window.open('https://connect.stripe.com/app/express', '_blank')}
          >
            Change Payout Account
          </button>
        </div>
      </div>
    </div>
  );
};

export default WalletView;

import React from 'react';
import { useNavigate } from 'react-router-dom';

function PrivacyPolicy() {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  return (
    <div className="flex flex-col items-center justify-center p-10 min-h-screen bg-gray-100 dark:bg-gray-900">
      <div className="w-full max-w-xl bg-white dark:bg-gray-800 p-10 rounded-lg shadow-md">
        <button onClick={goBack} className="mb-5 text-blue-500 hover:underline dark:text-blue-300">
          Back
        </button>
        <h1 className="text-2xl font-bold mb-5 text-center dark:text-white">Privacy Policy</h1>
        <p className="mb-5 text-center dark:text-gray-300"><strong>Last Updated: October 14, 2023</strong></p>
        <h2 className="text-xl font-bold mb-3 dark:text-white">Introduction</h2>
        <p className="mb-5 dark:text-gray-300">This Privacy Policy governs how Klorah collects, uses, and discloses your personal information.</p>
        <h2 className="text-xl font-bold mb-3 dark:text-white">Information Collection</h2>
        <p className="mb-5 dark:text-gray-300">We collect information like your name, email, and payment details.</p>
        <h2 className="text-xl font-bold mb-3 dark:text-white">Third-Party Services</h2>
        <h3 className="text-lg font-bold mb-2 dark:text-gray-300">Stripe</h3>
        <p className="mb-5 dark:text-gray-300">We use Stripe for payment processing. Your payment information is securely handled by Stripe and is not stored on our servers.</p>
        <h3 className="text-lg font-bold mb-2 dark:text-gray-300">Firebase</h3>
        <p className="mb-5 dark:text-gray-300">We use Firebase for backend services, which may include data storage and analytics.</p>
        <h3 className="text-lg font-bold mb-2 dark:text-gray-300">OpenAI</h3>
        <p className="mb-5 dark:text-gray-300">We use OpenAI models for AI-powered features in our service.</p>
        <h2 className="text-xl font-bold mb-3 dark:text-white">Information Use</h2>
        <p className="mb-5 dark:text-gray-300">We use this information to provide and improve our service.</p>
        <h2 className="text-xl font-bold mb-3 dark:text-white">Information Sharing</h2>
        <p className="mb-5 dark:text-gray-300">We do not sell your information to third parties but may share it with the third-party services mentioned above for operational purposes.</p>
        <h2 className="text-xl font-bold mb-3 dark:text-white">Security</h2>
        <p className="mb-5 dark:text-gray-300">We take reasonable measures to protect your information.</p>
        <h2 className="text-xl font-bold mb-3 dark:text-white">Changes to This Policy</h2>
        <p className="mb-5 dark:text-gray-300">We may update this Privacy Policy.</p>
        <h2 className="text-xl font-bold mb-3 dark:text-white">Contact Us</h2>
        <p className="dark:text-gray-300">If you have questions, contact us at <strong>support@klorah.zendesk.com</strong>.</p>
      </div>
    </div>
  );
}

export default PrivacyPolicy;

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faLinkedin, faTwitter, faFacebook } from '@fortawesome/free-brands-svg-icons';

const SocialMediaLinks = ({ socialMediaLinks }) => {
  return (
    <div>
      <div className="flex space-x-2">
        {socialMediaLinks?.instagram && (
          <a href={socialMediaLinks.instagram} target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faInstagram} size="1x" className="text-gray-600 dark:text-gray-400 hover:text-gray-800 dark:hover:text-gray-200 transition-colors duration-200" />
          </a>
        )}
        {socialMediaLinks?.linkedin && (
          <a href={socialMediaLinks.linkedin} target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faLinkedin} size="1x" className="text-gray-600 dark:text-gray-400 hover:text-gray-800 dark:hover:text-gray-200 transition-colors duration-200" />
          </a>
        )}
        {socialMediaLinks?.twitter && (
          <a href={socialMediaLinks.twitter} target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faTwitter} size="1x" className="text-gray-600 dark:text-gray-400 hover:text-gray-800 dark:hover:text-gray-200 transition-colors duration-200" />
          </a>
        )}
        {socialMediaLinks?.facebook && (
          <a href={socialMediaLinks.facebook} target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faFacebook} size="1x" className="text-gray-600 dark:text-gray-400 hover:text-gray-800 dark:hover:text-gray-200 transition-colors duration-200" />
          </a>
        )}
      </div>
    </div>
  );
};

export default SocialMediaLinks;
import React, { useState, useEffect } from 'react';
import { ref, getDownloadURL, deleteObject } from 'firebase/storage';
import { collection, getDocs } from 'firebase/firestore';
import { storage, db } from '../Firebase';
import { getAuth } from 'firebase/auth';
import NavigationBar from '../NavigationBar';
import { FaFileSignature, FaDownload, FaSearch, FaTrash } from 'react-icons/fa';
import { toast } from 'react-toastify';

const SignedDocuments = () => {
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredDocuments, setFilteredDocuments] = useState([]);

  useEffect(() => {
    fetchDocuments();
  }, []);

  useEffect(() => {
    const filtered = documents.filter(doc => 
      doc.title?.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredDocuments(filtered);
  }, [searchTerm, documents]);

  const fetchDocuments = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, 'agreements'));
      const docsWithUrls = await Promise.all(
        querySnapshot.docs.map(async (doc) => {
          try {
            const storageRef = ref(storage, `signed_agreements/${doc.id}.pdf`);
            const url = await getDownloadURL(storageRef);
            return {
              id: doc.id,
              title: doc.data().title || 'Untitled Document',
              url: url,
              fullPath: `signed_agreements/${doc.id}.pdf`,
              createdAt: doc.data().signedAt
            };
          } catch (error) {
            return null;
          }
        })
      );

      const validDocs = docsWithUrls
        .filter(doc => doc !== null)
        .sort((a, b) => b.createdAt - a.createdAt);

      setDocuments(validDocs);
      setFilteredDocuments(validDocs);
    } catch (error) {
      console.error('Error fetching documents:', error);
      toast.error('Failed to load documents');
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (document) => {
    try {
      const fileRef = ref(storage, document.fullPath);
      await deleteObject(fileRef);
      setDocuments(prev => prev.filter(doc => doc.id !== document.id));
      toast.success('Document deleted successfully');
    } catch (error) {
      console.error('Error deleting document:', error);
      toast.error('Failed to delete document');
    }
  };

  const DocumentCard = ({ document }) => (
    <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6 hover:shadow-xl transition-shadow duration-300">
      <div className="flex items-start justify-between">
        <div className="flex items-center space-x-4">
          <div className="p-3 bg-green-200 dark:bg-green-800 rounded-full">
            <FaFileSignature className="h-6 w-6 text-green-700 dark:text-green-300" />
          </div>
          <div>
            <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
              {document.title}
            </h3>
            {document.createdAt && (
              <p className="text-sm text-gray-500 dark:text-gray-400">
                Signed on {new Date(document.createdAt).toLocaleDateString()}
              </p>
            )}
          </div>
        </div>
        <div className="flex items-center space-x-4">
          <a
            href={document.url}
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center space-x-2 text-blue-500 dark:text-blue-300 hover:text-blue-700 dark:hover:text-blue-500"
          >
            <FaDownload className="h-5 w-5" />
            <span className="text-sm">Download</span>
          </a>
          <button
            onClick={() => handleDelete(document)}
            className="flex items-center space-x-2 text-red-500 dark:text-red-300 hover:text-red-700 dark:hover:text-red-500"
          >
            <FaTrash className="h-5 w-5" />
            <span className="text-sm">Delete</span>
          </button>
        </div>
      </div>
    </div>
  );

  return (
    <div className="min-h-screen bg-gray-100 dark:bg-gray-900">
      <NavigationBar />
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-10">
        <div className="flex justify-between items-center mb-10">
          <h1 className="text-3xl font-bold text-gray-900 dark:text-white">
            Signed Documents
          </h1>
          <div className="relative">
            <input
              type="text"
              placeholder="Search documents..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="pl-12 pr-4 py-3 border border-gray-300 dark:border-gray-600 rounded-full bg-white dark:bg-gray-800 text-gray-900 dark:text-white focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            />
            <FaSearch className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400" />
          </div>
        </div>

        {loading ? (
          <div className="flex justify-center items-center h-64">
            <div className="animate-spin rounded-full h-16 w-16 border-b-4 border-gray-900 dark:border-white"></div>
          </div>
        ) : filteredDocuments.length > 0 ? (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {filteredDocuments.map((doc) => (
              <DocumentCard key={doc.id} document={doc} />
            ))}
          </div>
        ) : (
          <div className="text-center py-16">
            <FaFileSignature className="mx-auto h-16 w-16 text-gray-400" />
            <h3 className="mt-4 text-xl font-medium text-gray-900 dark:text-white">
              No signed documents found
            </h3>
            <p className="mt-2 text-sm text-gray-500 dark:text-gray-400">
              {searchTerm ? 'No documents match your search' : 'When you sign documents, they will appear here'}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default SignedDocuments;
// CustomerList.js
import React from 'react';

export default function CustomerList({ customers, onSelect }) {
  if (customers.length === 0) {
    return null;
  }

  return (
    <ul className="mt-2 divide-y divide-gray-200 border border-gray-200 rounded-md dark:divide-gray-700 dark:border-gray-700">
      {customers.map((client) => (
        <li
          key={client.id}
          className="px-4 py-2 hover:bg-gray-100 cursor-pointer dark:hover:bg-gray-600"
          onClick={() => onSelect(client)}
        >
          {client.name}
        </li>
      ))}
    </ul>
  );
}
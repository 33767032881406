import React, { useEffect, useState } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { auth, db } from '../Firebase';
import { useNavigate } from 'react-router-dom';
import LoadingPage from './Agreement/loadingPage';
import NavigationBar from '../NavigationBar';
import { CheckIcon, StarIcon } from '@heroicons/react/24/solid';
import axios from 'axios';

const includedFeaturesPro = [
  'Reduced transaction fee of 2.9% instead of 3.3%',
  'Cutting-edge invoicing capabilities',
  'AI-generated or seamlessly imported client agreements',
  'Exclusive professional-grade tools',
  'Early access to new products and features',
];

const SubscriptionStatus = () => {
  const [subscription, setSubscription] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        const profileRef = doc(db, 'users', user.uid);
        const profileSnapshot = await getDoc(profileRef);
        if (profileSnapshot.exists()) {
          const profileData = profileSnapshot.data();
          setSubscription(profileData.isPro ? 'Pro' : 'Starter');
        }
      } else {
        navigate('/login');
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, [navigate]);

  const handleManageSubscription = async () => {
    try {
      const user = auth.currentUser;
      if (user) {
        const idToken = await user.getIdToken();
        const profileRef = doc(db, 'users', user.uid);
        const profileSnapshot = await getDoc(profileRef);
        if (profileSnapshot.exists()) {
          const profileData = profileSnapshot.data();
          const customerId = profileData.stripeCustomerId;
          if (!customerId) {
            setError("Stripe customer ID not found for the user.");
            return;
          }
          const response = await axios.post(
            'https://klorah-fast-server-9266fe8d441a.herokuapp.com/create-customer-portal-session',
            { userId: user.uid },
            { headers: { Authorization: `Bearer ${idToken}` } }
          );
          window.location.href = response.data.url;
        } else {
          setError("User profile not found.");
        }
      } else {
        setError("User not authenticated.");
      }
    } catch (error) {
      console.error("Error creating customer portal session:", error);
      setError(error.response?.data?.detail || error.message);
    }
  };

  if (loading) {
    return <LoadingPage />;
  }

  return (
    <div className="min-h-screen flex flex-col bg-gradient-to-r from-orange-400 via-orange-700 to-orange-600 text-white">
      <NavigationBar />
      <div className="flex-grow flex flex-col items-center justify-center px-4 py-8 sm:px-6 sm:py-12">
        <div className="max-w-4xl w-full text-center">
          <h2 className="text-3xl sm:text-5xl font-bold mb-4">Your Klorah Subscription</h2>
          <p className="text-xl sm:text-2xl mb-8">You are currently subscribed to Klorah's <strong>{subscription}</strong> plan.</p>
          {subscription === 'Pro' ? (
            <div className="bg-white text-gray-900 rounded-lg shadow-lg p-6 sm:p-8">
              <h3 className="text-2xl sm:text-3xl font-bold mb-4">Pro Member Benefits</h3>
              <ul className="mb-6 space-y-4 text-base sm:text-lg text-left">
                {includedFeaturesPro.map((feature) => (
                  <li key={feature} className="flex items-center">
                    <CheckIcon className="h-5 w-5 sm:h-6 sm:w-6 text-green-500 mr-2" aria-hidden="true" />
                    <span>{feature}</span>
                  </li>
                ))}
              </ul>
              <p className="text-base sm:text-lg">Thank you for being a Pro member of Klorah! Enjoy all the advanced features and dedicated support.</p>
              <button
                className="w-full py-3 px-4 rounded-lg bg-black text-white hover:bg-gray-700 transition duration-300 mt-4"
                onClick={handleManageSubscription}
              >
                Manage Subscription
              </button>
            </div>
          ) : (
            <>
              <div className="text-white p-6 sm:p-8 mb-8 sm:mb-12">
                <p className="text-base sm:text-lg mb-6">You are on the Starter plan with Klorah. Upgrade to Pro to unlock all the amazing features we offer!</p>
              </div>
              <div className="bg-white text-gray-900 rounded-lg shadow-lg p-6 sm:p-8">
                <h3 className="text-2xl sm:text-3xl font-bold mb-4 text-center">Unlock These Pro Features</h3>
                <ul className="mb-6 space-y-4 text-base sm:text-lg text-left">
                  {includedFeaturesPro.map((feature) => (
                    <li key={feature} className="flex items-center">
                      <StarIcon className="h-5 w-5 sm:h-6 sm:w-6 text-yellow-500 mr-2" aria-hidden="true" />
                      <span>{feature}</span>
                    </li>
                  ))}
                </ul>
                <button
                  className="w-full py-2 sm:py-3 px-4 rounded-lg bg-gray-900 text-white hover:bg-gray-700 transition duration-300"
                  onClick={() => navigate('/membership-billing')}
                >
                  Get 14 Day Free Trial
                </button>
              </div>
            </>
          )}
          {error && (
            <div className="bg-red-100 text-red-700 p-5 border border-red-400 rounded mt-5">
              <p>There was an error: {error}</p>
              <p>Please disable any ad blockers and try again. If the error persists, contact us at:</p>
              <p><a href="mailto:support@klorah.zendesk.com" className="text-blue-500">support@klorah.zendesk.com</a></p>
              <p>or call us at: <strong>+1 (856) 699-6475</strong></p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SubscriptionStatus;

// InvoicePreview.js
import React from 'react';

export default function InvoicePreview({ invoiceData, invoiceTheme, setIsThemeModalOpen }) {
  const themes = [
    { id: 'default', name: 'Snow White', primaryColor: '#ffffff', secondaryColor: '#f3f4f6', textColor: '#000000' },
    { id: 'modern', name: 'Minty Fresh', primaryColor: '#e0f2f1', secondaryColor: '#b2dfdb', textColor: '#004d40' },
    { id: 'classic', name: 'Golden Sunrise', primaryColor: '#fff8e1', secondaryColor: '#ffecb3', textColor: '#ff6f00' },
    { id: 'elegant', name: 'Rose Petal', primaryColor: '#fce4ec', secondaryColor: '#f8bbd0', textColor: '#880e4f' },
  ];

  const selectedTheme = themes.find(theme => theme.id === invoiceTheme);

  return (
    <div className="mt-10 lg:mt-0 pb-4 text-black dark:text-white">
      <h2 className="text-2xl font-semibold mb-4">Invoice Preview</h2>
      <div
        style={{
          backgroundColor: selectedTheme.primaryColor,
          color: selectedTheme.textColor,
          border: `2px solid ${selectedTheme.secondaryColor}`,
          borderRadius: '12px',
          padding: '30px',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        }}
      >
        <h3 
          style={{ 
            borderBottom: `2px solid ${selectedTheme.secondaryColor}`, 
            paddingBottom: '15px', 
            marginBottom: '25px',
            fontSize: '24px',
            fontWeight: 'bold'
          }}
        >
          Invoice
        </h3>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <p className="mb-3">
              <span className="font-semibold">Gig:</span>
              <span className="ml-2">{invoiceData.gig || 'N/A'}</span>
            </p>
            <p className="mb-3">
              <span className="font-semibold">Customer:</span>
              <span className="ml-2">{invoiceData.customer || 'N/A'}</span>
            </p>
          </div>
          <div>
            <p className="mb-3">
              <span className="font-semibold">Amount:</span>
              <span className="ml-2">
                {invoiceData.currency} {typeof invoiceData.price === 'number' ? invoiceData.price.toFixed(2) : '0.00'}
              </span>
            </p>
            <p className="mb-3">
              <span className="font-semibold">Due Date:</span>
              <span className="ml-2">{invoiceData.dueDate.toLocaleDateString()}</span>
            </p>
          </div>
        </div>
      </div>
      <button
        type="button"
        onClick={() => setIsThemeModalOpen(true)}
        className="mt-6 w-full bg-gray-200 text-black py-3 rounded-md hover:bg-gray-300 transition duration-300 dark:bg-gray-700 dark:text-white dark:hover:bg-gray-600"
      >
        Change Invoice Theme
      </button>
    </div>
  );
}
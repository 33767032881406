import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { getFirestore, doc, setDoc, getDoc, Timestamp } from 'firebase/firestore';
import mixpanel from 'mixpanel-browser';
import { motion } from 'framer-motion';
import { ArrowRightIcon, LightBulbIcon, RocketLaunchIcon, CurrencyDollarIcon, UserGroupIcon } from '@heroicons/react/24/outline';
import LoadingPage from '../Dashboard/Agreement/loadingPage';
import axios from 'axios';
import { loadStripe } from '@stripe/stripe-js';

const questions = [
  {
    id: 'primarySkill',
    text: "What's your primary freelance skill or service?",
    type: "text",
    placeholder: "e.g., Web Development, Graphic Design, Content Writing"
  },
  {
    id: 'idealClient',
    text: "Who is your ideal client? Describe their industry and size.",
    type: "text",
    placeholder: "e.g., Small tech startups, Medium-sized e-commerce businesses"
  },
  {
    id: 'mainProblem',
    text: "What's the main problem you solve for your clients?",
    type: "text",
    placeholder: "e.g., Increasing website conversions, Creating brand identity"
  },
  {
    id: 'uniqueService',
    text: "What makes your service unique compared to others?",
    type: "text",
    placeholder: "e.g., Specialized industry knowledge, Rapid turnaround time"
  }, 
  {
    id: 'biggestAchievement',
    text: "What's your biggest achievement or an impressive result you've delivered for a client?",
    type: "text",
    placeholder: "e.g., Increased client's revenue by 50%, Reduced operational costs by 30%"
  },
  {
    id: 'experience',
    text: "How much experience do you have in your field?",
    type: "select",
    options: ["Less than 1 year", "1-3 years", "3-5 years", "5+ years"]
  },
  {
    id: 'preferredContact',
    text: "What's your preferred method for initial client contact?",
    type: "select",
    options: ["Email", "Phone call", "Video call", "In-person meeting"]
  }
];

function Feature({ icon, title, description }) {
  return (
    <div className="flex items-center space-x-4 bg-black bg-opacity-50 p-4 rounded-xl shadow-md">
      <div className="flex-shrink-0">{icon}</div>
      <div>
        <h3 className="text-lg font-medium text-orange-400">{title}</h3>
        <p className="text-orange-100">{description}</p>
      </div>
    </div>
  );
}

export default function ChallengeSetup() {
  const [showIntro, setShowIntro] = useState(true);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [answers, setAnswers] = useState({});
  const [currentInput, setCurrentInput] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isPaymentLoading, setIsPaymentLoading] = useState(false);
  const navigate = useNavigate();
  const auth = getAuth();
  const [stripe, setStripe] = useState(null);

  useEffect(() => {
    const initializeStripe = async () => {
      const stripeInstance = await loadStripe('pk_live_51IuUXbLwpaCHfpZ1EZNNYaQYdXSIrE6NrdA3r7lUQOIq5tsdhC7MaTITIMFb3nW4yxqyppRd8C66QcxWhuoPC8aa00Ow2q0o0z');
      setStripe(stripeInstance);
    };

    const checkExistingChallenge = async () => {
      const userId = auth.currentUser.uid;
      const db = getFirestore();
      const progressDoc = await getDoc(doc(db, 'challengeProgress', userId));
      
      if (progressDoc.exists()) {
        // User is already in a challenge, redirect to dashboard
        navigate('/challenge-dashboard');
      }
      setIsLoading(false);
    };

    initializeStripe();
    checkExistingChallenge();
  }, [auth, navigate]);

  const handleAnswer = (answer) => {
    setAnswers({ ...answers, [questions[currentQuestion].id]: answer });
    setCurrentInput('');
  };

  const handleNextQuestion = () => {
    if (currentQuestion < questions.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
      setCurrentInput('');
    } else {
      setCurrentQuestion(questions.length); // Set to a value beyond the questions array length
    }
  };

  const handlePayment = async () => {
    setIsPaymentLoading(true);
    try {
        const idToken = await auth.currentUser.getIdToken();
        const userId = auth.currentUser.uid;
        console.log("User ID:", userId);

        // First, get or create a Stripe customer
        let stripeCustomerId;
        try {
            const customerRes = await axios.post('https://klorah-fast-server-9266fe8d441a.herokuapp.com/create-customer', {
                email: auth.currentUser.email,
            }, {
                headers: {
                    Authorization: `Bearer ${idToken}`,
                },
            });
            stripeCustomerId = customerRes.data.stripe_customer_id;
            console.log("Stripe Customer ID:", stripeCustomerId);
        } catch (error) {
            console.error('Error creating/retrieving Stripe customer:', error.response?.data || error.message);
            throw error;
        }

        // Create the challenge checkout session
        const sessionRes = await axios.post('https://klorah-fast-server-9266fe8d441a.herokuapp.com/createChallengeCheckoutSession', {
            userId,
            stripeCustomerId,
            priceId: 'price_1Q0qLlLwpaCHfpZ14ltqa30J' // Replace with your actual price ID
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${idToken}`
            }
        });

        console.log("Session Response:", sessionRes.data);

        const { sessionId } = sessionRes.data;

        const { error } = await stripe.redirectToCheckout({ sessionId });

        if (error) {
            console.error('Stripe checkout error:', error);
        }
    } catch (error) {
        console.error('Payment error:', error.response?.data || error.message);
    } finally {
        setIsPaymentLoading(false);
    }
};


  const handleSubmit = async () => {
    const userId = auth.currentUser.uid;
    const db = getFirestore();
    try {
      // Check if the user has already started the challenge
      const progressDoc = await getDoc(doc(db, 'challengeProgress', userId));
      
      if (progressDoc.exists()) {
        // User has already started the challenge
        navigate('/challenge-dashboard');
        return;
      }

      // Prepare the data with both questions and answers
      const questionAnswerPairs = questions.map(question => ({
        questionId: question.id,
        questionText: question.text,
        answer: answers[question.id] || ''
      }));

      // Save answers to Firestore
      await setDoc(doc(db, 'challengeAnswers', userId), {
        questionAnswerPairs,
        submittedAt: Timestamp.now()
      });

      // Trigger payment process
      await handlePayment();

      // Note: The actual challenge start will be handled by the webhook on successful payment
    } catch (error) {
      console.error("Error starting challenge:", error);
      // Handle error (show message to user, etc.)
    }
  };

  const renderIntro = () => (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="text-center space-y-8"
    >
      <h2 className="text-4xl font-extrabold text-white">
        Revolutionize Your Freelance Career
      </h2>
      <p className="text-xl text-orange-100 max-w-2xl mx-auto">
        Embark on a transformative 30-day journey designed to catapult your freelance business to new heights.
      </p>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <Feature
          icon={<LightBulbIcon className="w-8 h-8 text-orange-400" />}
          title="Craft a Magnetic Presence"
          description="Attract high-value clients with an irresistible online profile"
        />
        <Feature
          icon={<RocketLaunchIcon className="w-8 h-8 text-orange-400" />}
          title="Master Cold Outreach"
          description="Learn the art of engaging potential clients effectively"
        />
        <Feature
          icon={<CurrencyDollarIcon className="w-8 h-8 text-orange-400" />}
          title="Consistent Client Acquisition"
          description="Develop a bulletproof system for steady income"
        />
        <Feature
          icon={<UserGroupIcon className="w-8 h-8 text-orange-400" />}
          title="Think Like a Top Freelancer"
          description="Transform your mindset for long-term success"
        />
      </div>
      <div className="bg-black bg-opacity-50 p-6 rounded-2xl shadow-lg">
        <p className="text-2xl font-bold text-white mb-2">
          Invest in Your Future
        </p>
        <p className="text-4xl font-extrabold text-orange-400 mb-4">
          $15 for 30 Days of Guided Growth
        </p>
        <p className="text-orange-100 text-lg">
          Our guarantee: If you don't land a client by the end of the challenge, we'll refund your investment.
        </p>
      </div>
      <motion.button
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        className="w-full flex justify-center items-center py-4 px-6 border border-transparent rounded-xl text-xl font-medium text-orange-500 bg-black hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 shadow-lg"
        onClick={() => setShowIntro(false)}
      >
        Begin Your Transformation
        <ArrowRightIcon className="w-6 h-6 ml-2" />
      </motion.button>
    </motion.div>
  );

  const renderQuestion = () => {
    const question = questions[currentQuestion];
    if (question.type === "text") {
      return (
        <>
          <motion.input
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            type="text"
            value={currentInput}
            placeholder={question.placeholder}
            className="w-full px-4 py-3 border border-orange-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-orange-500 focus:border-orange-500 transition duration-300 ease-in-out bg-black text-white"
            onChange={(e) => setCurrentInput(e.target.value)}
          />
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            disabled={question.type === 'text' && !currentInput.trim()}
            className={`mt-4 w-full flex justify-center items-center py-4 px-6 border border-transparent rounded-xl text-xl font-medium ${
              question.type === 'text' && !currentInput.trim()
                ? 'text-gray-400 bg-gray-700 cursor-not-allowed'
                : 'text-orange-500 bg-black hover:bg-gray-900'
            } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 shadow-lg`}
            onClick={() => {
              handleAnswer(currentInput);
              handleNextQuestion();
            }}
          >
            Next
            <ArrowRightIcon className="w-6 h-6 ml-2" />
          </motion.button>
        </>
      );
    } else if (question.type === "select") {
      return (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {question.options.map((option, index) => (
            <motion.button
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              className="w-full flex justify-center py-3 px-4 border border-transparent rounded-lg text-lg font-medium text-orange-500 bg-black hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 shadow-md transition duration-300 ease-in-out"
              onClick={() => {
                handleAnswer(option);
                handleNextQuestion();
              }}
            >
              {option}
            </motion.button>
          ))}
        </div>
      );
    }
  };

  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <div className="min-h-screen bg-gradient-to-r from-orange-400 via-orange-500 to-orange-600 flex items-center justify-center px-4 sm:px-6 lg:px-8">
      <div className="max-w-4xl w-full space-y-8 bg-black bg-opacity-80 p-10 rounded-3xl shadow-2xl text-white">
        {showIntro ? (
          renderIntro()
        ) : (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <h2 className="mt-6 text-center text-3xl font-extrabold text-orange-400 mb-8">
              30-Day Freelancer Challenge
            </h2>
            <div className="space-y-6">
              <div className="mb-4 text-orange-300">
                Question {currentQuestion + 1} of {questions.length}
              </div>
              {currentQuestion < questions.length ? (
                <>
                  <h3 className="text-xl font-medium text-white mb-4">
                    {questions[currentQuestion].text}
                  </h3>
                  {renderQuestion()}
                </>
              ) : currentQuestion === questions.length ? (
                <div className="text-center">
                  <h3 className="text-2xl font-medium text-white mb-6">
                    Great! You're all set to start the challenge.
                  </h3>
                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    className="w-full flex justify-center items-center py-4 px-6 border border-transparent rounded-xl text-xl font-medium text-orange-500 bg-black hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 shadow-lg"
                    onClick={handleSubmit}
                    disabled={isPaymentLoading}
                  >
                    {isPaymentLoading ? (
                      <span className="flex items-center">
                        <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-orange-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                        Processing...
                      </span>
                    ) : (
                      <>
                        Pay and Start Challenge ($15)
                        <RocketLaunchIcon className="w-6 h-6 ml-2" />
                      </>
                    )}
                  </motion.button>
                </div>
              ) : null}
            </div>
          </motion.div>
        )}
      </div>
    </div>
  );
}
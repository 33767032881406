import React, { createContext, useState, useContext, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../Firebase'; // Adjust the import path as needed

const OnboardingContext = createContext();

export const useOnboarding = () => {
  const context = useContext(OnboardingContext);
  if (!context) {
    throw new Error('useOnboarding must be used within an OnboardingProvider');
  }
  return context;
};

export const OnboardingProvider = ({ children }) => {
  const [onboardingState, setOnboardingState] = useState({
    isOnboardingComplete: null,
    isLoading: true,
    user: null
  });

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      console.log("OnboardingProvider: Auth state changed", currentUser ? currentUser.uid : "No user");
      if (currentUser) {
        const userDocRef = doc(db, 'users', currentUser.uid);
        try {
          const userDoc = await getDoc(userDocRef);
          if (userDoc.exists()) {
            const userData = userDoc.data();
            setOnboardingState({
              isOnboardingComplete: !!userData.onboardingComplete,
              isLoading: false,
              user: currentUser
            });
          } else {
            setOnboardingState({
              isOnboardingComplete: false,
              isLoading: false,
              user: currentUser
            });
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
          setOnboardingState({
            isOnboardingComplete: false,
            isLoading: false,
            user: currentUser
          });
        }
      } else {
        setOnboardingState({
          isOnboardingComplete: false,
          isLoading: false,
          user: null
        });
      }
    });

    return () => unsubscribe();
  }, []);

  const updateOnboardingStatus = async (status) => {
    if (!onboardingState.user) {
      console.error("No user logged in");
      return;
    }

    setOnboardingState(prev => ({ ...prev, isLoading: true }));
    try {
      const userDocRef = doc(db, 'users', onboardingState.user.uid);
      await updateDoc(userDocRef, { onboardingComplete: status });
      setOnboardingState(prev => ({
        ...prev,
        isOnboardingComplete: status,
        isLoading: false
      }));
    } catch (error) {
      console.error("Error updating onboarding status:", error);
      setOnboardingState(prev => ({ ...prev, isLoading: false }));
      throw error;
    }
  };

  return (
    <OnboardingContext.Provider 
      value={{ 
        ...onboardingState,
        updateOnboardingStatus
      }}
    >
      {children}
    </OnboardingContext.Provider>
  );
};
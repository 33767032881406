import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import NavigationBar from '../../NavigationBar';
import LoadingPage from './loadingPage';
import { db, storage } from '../../Firebase';
import { collection, addDoc, serverTimestamp, doc, getDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL, listAll, deleteObject } from 'firebase/storage';
import { getAuth } from 'firebase/auth';
import useCurrentUser from '../../Authentication/currentUser';
import mixpanel from 'mixpanel-browser';
import DarkModeButton from '../../DarkModeButton';
import ReactQuill from 'react-quill'; // Import React Quill for rich text editing
import 'react-quill/dist/quill.snow.css'; // Import Quill styles

const CreateAgreement = () => {
  const navigate = useNavigate();
  const [date, setDate] = useState(new Date());
  const [isEnabled, setIsEnabled] = useState(false);
  const [currency, setCurrency] = useState('USD');
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [scopeOfWork, setScopeOfWork] = useState('');
  const [price, setPrice] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { username } = useCurrentUser();
  const [currentUser, setCurrentUser] = useState(() => {
    const auth = getAuth();
    return auth.currentUser;
  });
  const [clientName, setClientName] = useState('');
  const [title, setTitle] = useState('');
  const [referenceFiles, setReferenceFiles] = useState([]);
  const [selectedReference, setSelectedReference] = useState(null);
  const [isProUser, setIsProUser] = useState(false);
  const [mode, setMode] = useState('generative'); // New state for tracking the mode
  const [manualAgreement, setManualAgreement] = useState(''); // New state for manual agreement text

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setCurrentUser(user);
        fetchUserProStatus(user.uid);
      }
    });
    return () => unsubscribe();
  }, []);

  const fetchUserProStatus = async (userId) => {
    const docRef = doc(db, 'users', userId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const userData = docSnap.data();
      setIsProUser(userData.isPro || false);
    } else {
      console.error('No such document!');
    }
  };

  useEffect(() => {
    const today = new Date().toLocaleDateString();
    setTitle(`Agreement with ${clientName} - ${today}`);
  }, [clientName]);

  const fetchReferenceFiles = async (userId) => {
    if (!isProUser) return;
    const storageRef = ref(storage, `reference_files/${userId}`);
    const fileList = await listAll(storageRef);

    const filesPromises = fileList.items.map(async (item) => {
      const url = await getDownloadURL(item);
      return { name: item.name, url };
    });

    const files = await Promise.all(filesPromises);
    setReferenceFiles(files);
  };

  const handleUploadReference = async (file) => {
    if (!currentUser || !isProUser) return;

    const storageRef = ref(storage, `reference_files/${currentUser.uid}/${file.name}`);
    await uploadBytes(storageRef, file);
    await fetchReferenceFiles(currentUser.uid);
  };

  const handleDeleteReference = async (fileName) => {
    if (!currentUser || !isProUser) return;

    const storageRef = ref(storage, `reference_files/${currentUser.uid}/${fileName}`);
    await deleteObject(storageRef);
    await fetchReferenceFiles(currentUser.uid);

    if (selectedReference && selectedReference.name === fileName) {
      setSelectedReference(null);
    }
  };

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles.length > 0 && isProUser) {
      handleUploadReference(acceptedFiles[0]);
    }
  }, [currentUser, isProUser]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'application/pdf': ['.pdf'],
      'application/msword': ['.doc'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx']
    },
    multiple: false
  });

  const handleGenerate = async () => {
    setIsLoading(true);
    mixpanel.track("Agreement Generation Initiated", { 
      "User": username, 
      "Client": clientName, 
      "Scope of Work": scopeOfWork, 
      "Price": price, 
      "Currency": currency,
      "Mode": mode
    });

    if (currentUser && currentUser.uid) {
      if (date instanceof Date) {
        let referenceFileUrl = selectedReference ? selectedReference.url : '';

        const formattedDate = date.toISOString().split('T')[0];
        let agreementText;

        if (mode === 'generative') {
          const contractData = {
            scope_of_work: scopeOfWork,
            price: parseFloat(price),
            currency: currency,
            due_date: formattedDate,
            freelancer_name: username,
            client_name: clientName,
            reference_file_url: referenceFileUrl,
          };

          try {
            const response = await fetch('https://klorah-fast-server-9266fe8d441a.herokuapp.com/generate-contract', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(contractData),
            });

            if (response.ok) {
              const data = await response.json();
              agreementText = data.contract_text;
            } else {
              throw new Error(response.statusText);
            }
          } catch (error) {
            console.error('Error generating agreement:', error);
            mixpanel.track("Agreement Generation Failed", { 
              "User": username, 
              "Client": clientName, 
              "Error": error.message,
              "Mode": mode
            });
            setIsLoading(false);
            return;
          }
        } else {
          agreementText = manualAgreement;
        }

        const agreementData = {
          text: agreementText,
          name: title,
          date: formattedDate,
          amount: price,
          userId: currentUser.uid,
          created: serverTimestamp(),
          reference_file_url: referenceFileUrl,
          mode: mode,
        };

        try {
          const docRef = await addDoc(collection(db, 'agreements'), agreementData);
          navigate(`/share-agreement/${docRef.id}`);
          mixpanel.track("Agreement Generation Successful", { 
            "User": username, 
            "Client": clientName, 
            "Agreement ID": docRef.id,
            "Mode": mode
          });
        } catch (error) {
          console.error('Error saving agreement:', error);
          mixpanel.track("Agreement Generation Failed", { 
            "User": username, 
            "Client": clientName, 
            "Error": error.message,
            "Mode": mode
          });
        }
      } else {
        console.error('Invalid date');
        mixpanel.track("Agreement Generation Failed", { 
          "User": username, 
          "Client": clientName, 
          "Error": "Invalid date",
          "Mode": mode
        });
      }
    } else {
      console.error('No user is logged in.');
      mixpanel.track("Agreement Generation Failed", { 
        "User": username, 
        "Client": clientName, 
        "Error": "No user is logged in",
        "Mode": mode
      });
    }
    setIsLoading(false);
  };

  const onChange = (event) => {
    const newDate = new Date(event.target.value);
    setDate(newDate);
  };

  const currencies = ['USD', 'EUR', 'JPY', 'GBP', 'AUD', 'CAD', 'CHF', 'CNY', 'HKD', 'NZD'];

  const toggleSwitch = () => setIsEnabled(previousState => !previousState);

  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <div className="bg-white dark:bg-black text-black dark:text-white min-h-screen">
      <NavigationBar />
      <DarkModeButton />
      <div className="flex flex-col items-center justify-center p-5 pt-20">
        <div className="w-full max-w-2xl bg-white dark:bg-gray-800 rounded-lg border border-gray-300 dark:border-gray-700 p-9">
          {/* Navigation panel */}
          <div className="flex justify-center mb-5 border-b border-gray-300 dark:border-gray-600">
            <button
              className={`px-4 py-2 mr-4 font-medium text-lg focus:outline-none ${
                mode === 'generative'
                  ? 'text-black dark:text-white border-b-2 border-black dark:border-white'
                  : 'text-gray-500 dark:text-gray-400'
              }`}
              onClick={() => setMode('generative')}
            >
              Generative
            </button>
            <button
              className={`px-4 py-2 font-medium text-lg focus:outline-none ${
                mode === 'manual'
                  ? 'text-black dark:text-white border-b-2 border-black dark:border-white'
                  : 'text-gray-500 dark:text-gray-400'
              }`}
              onClick={() => setMode('manual')}
            >
              Manual
            </button>
          </div>

          <h1 className="text-2xl font-semibold mb-5">Create Agreement {mode === 'generative' ? 'using AI' : 'manually'}</h1>

          {mode === 'generative' ? (
            <>
              <div className="mb-6">
                <label className="block text-lg font-medium mb-2">Describe the scope of work for this contract?</label>
                <input
                  type="text"
                  value={scopeOfWork}
                  onChange={(e) => setScopeOfWork(e.target.value)}
                  placeholder="Enter scope of work details"
                  className="w-full h-12 border border-gray-300 dark:border-gray-600 rounded-lg px-4 focus:ring-2 focus:ring-indigo-500 focus:border-transparent dark:bg-gray-700 dark:text-white"
                />
              </div>

              {/* Add back the price section */}
              <div className="mb-6">
                <label className="block text-lg font-medium mb-2">Price</label>
                <div className="flex items-center gap-2">
                  <div className="relative w-1/3">
                    <button onClick={() => setDropdownVisible(!dropdownVisible)} className="w-full h-12 border border-gray-300 dark:border-gray-600 rounded-lg px-4 text-gray-700 dark:text-white focus:outline-none focus:ring-2 focus:ring-indigo-500">
                      {currency}
                    </button>
                    {dropdownVisible && (
                      <div className="absolute top-full mt-1 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg max-h-36 overflow-y-scroll z-10">
                        {['USD', 'EUR', 'JPY', 'GBP', 'AUD', 'CAD', 'CHF', 'CNY', 'HKD', 'NZD'].map((item, index) => (
                          <button key={index} onClick={() => { setCurrency(item); setDropdownVisible(false); }} className="block w-full text-left px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600">
                            {item}
                          </button>
                        ))}
                      </div>
                    )}
                  </div>
                  <input
                    type="text"
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                    placeholder="Enter price"
                    className="w-2/3 h-12 border border-gray-300 dark:border-gray-600 rounded-lg px-4 focus:ring-2 focus:ring-indigo-500 focus:border-transparent dark:bg-gray-700 dark:text-white"
                  />
                </div>
              </div>

              {/* ... (other fields for generative mode) */}
            </>
          ) : (
            // Manual mode content
            <div className="mb-6">
              <label className="block text-lg font-medium mb-2">Agreement Text</label>
              <ReactQuill
                value={manualAgreement}
                onChange={setManualAgreement}
                className="h-64 mb-12"
              />
            </div>
          )}

          {/* Common fields for both modes */}
          <div className="flex items-center gap-2 mb-6">
            <div className="w-1/2">
              <label className="block text-lg font-medium mb-2">Client's Name</label>
              <input
                type="text"
                value={clientName}
                onChange={(e) => setClientName(e.target.value)}
                placeholder="Enter client's name"
                className="w-full h-12 border border-gray-300 dark:border-gray-600 rounded-lg px-4 focus:ring-2 focus:ring-indigo-500 focus:border-transparent dark:bg-gray-700 dark:text-white"
              />
            </div>
            <div className="w-1/2">
              <label className="block text-lg font-medium mb-2">Due Date</label>
              <input
                type="date"
                value={date.toISOString().split('T')[0]}
                onChange={onChange}
                className="w-full h-12 border border-gray-300 dark:border-gray-600 rounded-lg px-4 focus:ring-2 focus:ring-indigo-500 focus:border-transparent dark:bg-gray-700 dark:text-white"
              />
            </div>
          </div>

          <div className="mb-6">
            <label className="block text-lg font-medium mb-2">Agreement Title</label>
            <input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="Enter agreement title"
              className="w-full h-12 border border-gray-300 dark:border-gray-600 rounded-lg px-4 focus:ring-2 focus:ring-indigo-500 focus:border-transparent dark:bg-gray-700 dark:text-white"
            />
          </div>

          {isProUser && (
            <>
              <div className="mb-6">
                <label className="block text-lg font-medium mb-2">Upload Reference Agreement</label>
                <div {...getRootProps()} className={`border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-lg p-4 text-center cursor-pointer ${isDragActive ? 'bg-gray-100 dark:bg-gray-700' : ''}`}>
                  <input {...getInputProps()} />
                  <p>Drag 'n' drop a file here, or click to select a file</p>
                </div>
              </div>

              {referenceFiles.length > 0 && (
                <div className="mb-6">
                  <label className="block text-lg font-medium mb-2">Select Reference Agreement</label>
                  <div className="space-y-2">
                    {referenceFiles.map((file) => (
                      <div key={file.name} className="flex items-center justify-between p-2 border rounded">
                        <div className="flex items-center">
                          <input
                            type="radio"
                            id={file.name}
                            name="referenceFile"
                            checked={selectedReference && selectedReference.name === file.name}
                            onChange={() => setSelectedReference(file)}
                            className="mr-2"
                          />
                          <label htmlFor={file.name}>{file.name}</label>
                        </div>
                        <div>
                          <button
                            onClick={() => window.open(file.url, '_blank')}
                            className="text-blue-500 hover:text-blue-700 mr-2"
                          >
                            View
                          </button>
                          <button
                            onClick={() => handleDeleteReference(file.name)}
                            className="text-red-500 hover:text-red-700"
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </>
          )}

          <button
            onClick={handleGenerate}
            className="w-full h-12 bg-black text-white rounded-lg hover:bg-gray-800 dark:bg-white dark:text-black dark:hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            {mode === 'generative' ? 'Generate Agreement' : 'Save Agreement'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreateAgreement;
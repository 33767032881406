import React, { useState, useEffect } from 'react';
import { db } from '../Firebase';
import { collection, getDocs, query, where, deleteDoc, doc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import mixpanel from 'mixpanel-browser';

const ChevronRightIcon = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="m9 18 6-6-6-6" />
  </svg>
);

const NotificationFrame = ({ data, onPress, onSelect, selected }) => {
  const getDisplayText = () => {
    if (data.type === 'agreement') {
      return `${data.name || 'Agreement'} - ${data.date}`;
    } else if (data.type === 'invoice') {
      return `Invoice for ${data.customer || 'Customer'} - Due: ${data.due_date}`;
    }
  };

  const getAmount = () => {
    return data.type === 'agreement' ? `$${data.amount}` : `Price: $${data.price}`;
  };

  return (
    <div className="flex items-center justify-between p-4 bg-white dark:bg-black rounded-lg shadow-md border border-gray-200" onClick={onPress}>
      <input
        type="checkbox"
        id={`notification-${data.id}`}
        className="rounded-full dark:bg-black dark:border-white dark:checked:bg-white"
        style={{ backgroundColor: 'black', borderColor: 'white' }}
        onClick={(e) => { e.stopPropagation(); onSelect(data.id); }}
      />
      <label className="flex-1 ml-4 text-black dark:text-white" htmlFor={`notification-${data.id}`}>
        {getDisplayText()}
        <span className="block text-sm text-black dark:text-gray-300">{getAmount()}</span>
      </label>
      <ChevronRightIcon className="w-4 h-4 text-black dark:text-white" />
    </div>
  );
};

const BottomHalf = () => {
  const navigate = useNavigate();
  const [notifications, setNotifications] = useState([]);
  const [selectedNotifications, setSelectedNotifications] = useState([]);
  const auth = getAuth();
  const currentUser = auth.currentUser;
  const [sortOption, setSortOption] = useState('date');

  useEffect(() => {
    if (currentUser) {
      fetchNotifications();
    }
  }, [sortOption, currentUser]);

  const fetchNotifications = async () => {
    try {
      const agreementsQuery = query(collection(db, 'agreements'), where('userId', '==', currentUser.uid));
      const agreementsSnapshot = await getDocs(agreementsQuery);
      const agreements = agreementsSnapshot.docs.map(doc => ({ id: doc.id, type: 'agreement', ...doc.data() }));

      const invoicesQuery = query(collection(db, 'invoices'), where('userId', '==', currentUser.uid));
      const invoicesSnapshot = await getDocs(invoicesQuery);
      const invoices = invoicesSnapshot.docs.map(doc => ({ id: doc.id, type: 'invoice', ...doc.data() }));

      let combinedData = [...agreements, ...invoices];

      if (sortOption === 'paid_invoices') {
        combinedData = combinedData.filter(item => item.type === 'invoice' && item.status === 'paid');
      }

      combinedData.sort((a, b) => {
        if (sortOption === 'date') {
          return new Date(a.dueDate || a.date) - new Date(b.dueDate || b.date);
        } else if (sortOption === 'type') {
          return a.type.localeCompare(b.type);
        } else if (sortOption === 'amount') {
          return (a.amount || a.price) - (b.amount || b.price);
        }
        return 0;
      });

      setNotifications(combinedData);
    } catch (error) {
      console.error("Error fetching notifications: ", error);
    }
  };

  const navigateToDetailPage = (notification) => {
    mixpanel.track('Navigate to Detail Page', { 'Notification Type': notification.type });
    if (notification.type === 'agreement') {
      navigate(`/share-agreement/${notification.id}`);
    } else if (notification.type === 'invoice') {
      navigate(`/preview-invoice/${notification.id}`);
    }
  };

  const handleSelectNotification = (id) => {
    mixpanel.track('Notification Selected', { 'Notification ID': id });
    setSelectedNotifications(prev => prev.includes(id) ? prev.filter(notificationId => notificationId !== id) : [...prev, id]);
  };

  const handleDeleteNotification = async (id) => {
    try {
      const notification = notifications.find(notification => notification.id === id);
      if (notification) {
        await deleteDoc(doc(db, notification.type + 's', id));
        fetchNotifications();
      }
    } catch (error) {
      console.error("Error deleting notification: ", error);
    }
  };

  const handleMassDelete = async () => {
    for (const id of selectedNotifications) {
      await handleDeleteNotification(id);
    }
    fetchNotifications();
    setSelectedNotifications([]);
  };

  return (
    <div className="w-4/5 mx-auto p-6 mt-4">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-semibold text-black dark:text-white">Recents</h2>
        <div className="flex flex-col sm:flex-row items-center">
          <button className="bg-black text-white dark:bg-white dark:text-black mb-2 sm:mb-0 sm:mr-4 border-black dark:border-white border-2 rounded-md px-2 py-1" onClick={() => navigate('/invoices')}>View All</button>
          {selectedNotifications.length > 0 && (
            <button className="bg-red-600 text-white dark:text-black mb-2 sm:mb-0 sm:mr-4 rounded-md px-2 py-1" onClick={handleMassDelete}>Delete Selected</button>
          )}
          <select 
            className="bg-black text-white dark:bg-white dark:text-black border-black dark:border-white border-2 rounded-md px-2 py-1" 
            value={sortOption} 
            onChange={(e) => setSortOption(e.target.value)}
          >
            <option value="date">Sort by Date</option>
            <option value="type">Sort by Type</option>
            <option value="amount">Sort by Amount</option>
            <option value="paid_invoices">Paid Invoices</option>
          </select>
        </div>
      </div>
      <div className="space-y-2">
        {notifications.length > 0 ? (
          notifications.map((notification) => (
            <NotificationFrame
              key={notification.id}
              data={notification}
              onPress={() => navigateToDetailPage(notification)}
              onSelect={handleSelectNotification}
              selected={selectedNotifications.includes(notification.id)}
            />
          ))
        ) : (
          <div className="text-black dark:text-white text-lg text-center mt-5">No current activities to show</div>
        )}
      </div>
    </div>
  );
};

export default BottomHalf;

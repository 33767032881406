import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoon, faSun } from '@fortawesome/free-solid-svg-icons';

const DarkModeButton = ({ onDarkModeChange = () => {} }) => {
  const [darkMode, setDarkMode] = useState(() => {
    const localDarkMode = window.localStorage.getItem('darkMode');
    return localDarkMode ? JSON.parse(localDarkMode) : false;
  });

  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
    window.localStorage.setItem('darkMode', darkMode);
    onDarkModeChange(darkMode);
  }, [darkMode, onDarkModeChange]);

  const handleDarkModeToggle = () => {
    setDarkMode(!darkMode);
  };

  return (
    <div className="fixed bottom-4 left-4 z-50">
      <button
        onClick={handleDarkModeToggle}
        className="p-2 rounded-full shadow-lg bg-black dark:bg-white focus:outline-none"
        aria-label="Toggle Dark Mode"
      >
        <FontAwesomeIcon icon={darkMode ? faSun : faMoon} className="text-white dark:text-black" />
      </button>
    </div>
  );
};

export default DarkModeButton;

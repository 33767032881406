import React, { useState, useEffect } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { getFirestore, doc, getDoc, updateDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import NavigationBar from '../../NavigationBar';
import LoadingPage from '../Agreement/loadingPage';

const stripePromise = loadStripe("pk_live_51IuUXbLwpaCHfpZ1EZNNYaQYdXSIrE6NrdA3r7lUQOIq5tsdhC7MaTITIMFb3nW4yxqyppRd8C66QcxWhuoPC8aa00Ow2q0o0z");

const PaymentForm = ({ clientSecret, price, invoiceId, theme }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isProcessing, setIsProcessing] = useState(false);
  const [paymentError, setPaymentError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsProcessing(true);
    setPaymentError('');

    if (!stripe || !elements) {
      setPaymentError('Stripe has not been initialized.');
      setIsProcessing(false);
      return;
    }

    const { error: submitError } = await elements.submit();
    if (submitError) {
      console.error('Error submitting payment:', submitError);
      setPaymentError(submitError.message);
      setIsProcessing(false);
      return;
    }

    const result = await stripe.confirmPayment({
      elements,
      redirect: 'if_required',
    });

    if (result.error) {
      console.error('Error confirming payment:', result.error);
      setPaymentError(result.error.message);
      setIsProcessing(false);
    } else if (result.paymentIntent.status === 'succeeded') {
      console.log('Payment succeeded, updating invoice...');
      try {
        const firestore = getFirestore();
        const invoiceRef = doc(firestore, 'invoices', invoiceId);
        await updateDoc(invoiceRef, { 
          status: 'paid',
          paidAt: new Date()
        });
        console.log('Invoice updated successfully');
        navigate(`/payment-confirmation/${invoiceId}`);
      } catch (error) {
        console.error('Error updating invoice:', error);
        setPaymentError('Payment successful, but there was an error updating the invoice. Please contact support.');
      }
    }
    setIsProcessing(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" />
      <button 
        type="submit" 
        style={{
          backgroundColor: theme.textColor,
          color: theme.primaryColor,
        }}
        className="mt-4 w-full hover:opacity-80 font-semibold py-2 px-4 rounded-lg transition duration-300" 
        disabled={!stripe || isProcessing}
      >
        {isProcessing ? 'Processing...' : `Pay $${price}`}
      </button>
      {paymentError && <div className="text-red-500 mt-2">{paymentError}</div>}
    </form>
  );
};

const PaymentPage = () => {
  const { invoiceId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [clientSecret, setClientSecret] = useState('');
  const [invoiceData, setInvoiceData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [invoiceTheme, setInvoiceTheme] = useState('default');
  const [isConfirmingPayment, setIsConfirmingPayment] = useState(false);
  const auth = getAuth();
  const user = auth.currentUser;

  const themes = {
    default: { primaryColor: '#ffffff', secondaryColor: '#f3f4f6', textColor: '#000000' },
    modern: { primaryColor: '#e0f2f1', secondaryColor: '#b2dfdb', textColor: '#004d40' },
    classic: { primaryColor: '#fff8e1', secondaryColor: '#ffecb3', textColor: '#ff6f00' },
    elegant: { primaryColor: '#fce4ec', secondaryColor: '#f8bbd0', textColor: '#880e4f' },
  };

  const updateInvoiceStatus = async (invoiceId, status) => {
    const firestore = getFirestore();
    const invoiceRef = doc(firestore, 'invoices', invoiceId);
    await updateDoc(invoiceRef, { 
      status: status, 
      paidAt: new Date(),
      updatedAt: new Date() 
    });
  };

  useEffect(() => {
    const fetchInvoice = async () => {
      try {
        const firestore = getFirestore();
        const invoiceDocRef = doc(firestore, 'invoices', invoiceId);
        const invoiceDocSnap = await getDoc(invoiceDocRef);

        if (!invoiceDocSnap.exists()) {
          setError('Invoice not found.');
          return;
        }

        const invoice = invoiceDocSnap.data();
        setInvoiceData(invoice);
        setInvoiceTheme(invoice.theme || 'default');

        if (invoice.status === 'paid') {
          navigate(`/payment-confirmation/${invoiceId}`);
          return;
        }

        const response = await fetch('https://klorah-fast-server-9266fe8d441a.herokuapp.com/create-payment-intent', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            amount: invoice.price * 100,
            currency: invoice.currency,
            description: `${invoice.gig} for ${invoice.customer}`,
            invoiceId: invoiceId,
            connectedAccountId: invoice.connectedAccountId,
            userId: invoice.userId,
          }),
        });

        if (!response.ok) {
          const errorText = await response.text();
          setError(`Failed to create payment intent: ${errorText}`);
          return;
        }

        const paymentIntent = await response.json();
        setClientSecret(paymentIntent.clientSecret);
      } catch (error) {
        console.error('Error fetching invoice:', error);
        setError(`An error occurred while fetching the invoice: ${error.message}`);
      } finally {
        setIsLoading(false);
      }
    };

    fetchInvoice();
  }, [invoiceId, navigate]);

  useEffect(() => {
    const handlePaymentConfirmation = async () => {
      const urlParams = new URLSearchParams(location.search);
      const paymentIntentId = urlParams.get('payment_intent');
      const redirectStatus = urlParams.get('redirect_status');

      if (redirectStatus === 'succeeded' && invoiceData && invoiceData.status !== 'paid') {
        setIsConfirmingPayment(true);
        try {
          await updateInvoiceStatus(invoiceId, 'paid');
          navigate(`/payment-confirmation/${invoiceId}`);
        } catch (error) {
          console.error('Error updating invoice status:', error);
          setError('Payment was successful, but there was an error updating the invoice. Please contact support.');
        } finally {
          setIsConfirmingPayment(false);
        }
      }
    };

    handlePaymentConfirmation();
  }, [location, invoiceId, invoiceData, navigate]);

  const selectedTheme = themes[invoiceTheme] || themes.default;

  if (isLoading || isConfirmingPayment) return <LoadingPage />;

  if (error) {
    return (
      <div className="min-h-screen flex flex-col justify-center items-center px-4">
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
          <strong className="font-bold">Error: </strong>
          <span className="block sm:inline">{error}</span>
        </div>
      </div>
    );
  }

  return (
    <div>
      {user && <NavigationBar />}
      <div 
        className="min-h-screen flex flex-col justify-center items-center px-4"
        style={{
          backgroundColor: selectedTheme.primaryColor,
          color: selectedTheme.textColor,
        }}
      >
        <div className="w-full max-w-md">
          <h1 className="text-2xl font-semibold mb-4">Payment Details</h1>
          {invoiceData && (
            <div 
              className="shadow-md rounded-lg p-6 mb-4"
              style={{
                backgroundColor: selectedTheme.secondaryColor,
                color: selectedTheme.textColor,
                border: `2px solid ${selectedTheme.textColor}`,
              }}
            >
              <h2 className="text-2xl font-bold mb-4" style={{ borderBottom: `2px solid ${selectedTheme.textColor}` }}>Invoice Summary</h2>
              <div className="flex justify-between items-center mb-2">
                <p className="font-semibold">Customer:</p>
                <p>{invoiceData.customer}</p>
              </div>
              <div className="flex justify-between items-center mb-2">
                <p className="font-semibold">Gig:</p>
                <p>{invoiceData.gig}</p>
              </div>
              <div className="flex justify-between items-center mb-2">
                <p className="font-semibold">Price:</p>
                <p>${invoiceData.price}</p>
              </div>
              <div className="flex justify-between items-center mb-2">
                <p className="font-semibold">Status:</p>
                <p style={{ color: invoiceData.status === 'paid' ? 'green' : 'red' }}>
                  {invoiceData.status ? invoiceData.status.toUpperCase() : 'PENDING'}
                </p>
              </div>
            </div>
          )}
          {invoiceData && invoiceData.status !== 'paid' && clientSecret && (
            <Elements stripe={stripePromise} options={{ clientSecret: clientSecret }}>
              <PaymentForm 
                clientSecret={clientSecret} 
                price={invoiceData.price} 
                invoiceId={invoiceId} 
                theme={selectedTheme}
              />
            </Elements>
          )}
        </div>
        <div className="mt-8">
          <p className="text-sm" style={{ color: selectedTheme.textColor }}>
            Powered by <a href="https://klorah.com" className="hover:underline">Klorah</a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default PaymentPage;
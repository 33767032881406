import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { getAuth } from 'firebase/auth';

export default function Success() {
  const navigate = useNavigate();
  const auth = getAuth(); // Use getAuth instead of useAuth
  const [isUpdating, setIsUpdating] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const checkAndUpdateSubscription = async () => {
      setIsUpdating(true);
      setError(null);
      try {
        const user = auth.currentUser; // Get the current user
        if (!user) {
          throw new Error('No user logged in');
        }

        const idToken = await user.getIdToken();
        const response = await axios.post(
          'https://klorah-fast-server-9266fe8d441a.herokuapp.com/check-and-update-subscription',
          { userId: user.uid },
          {
            headers: {
              Authorization: `Bearer ${idToken}`
            }
          }
        );
        console.log('Subscription update response:', response.data);
        // You might want to update your local state or context here based on the response
      } catch (error) {
        console.error('Error updating subscription:', error);
        setError('Failed to update subscription. Please try again later.');
      } finally {
        setIsUpdating(false);
      }
    };

    checkAndUpdateSubscription();

    const timer = setTimeout(() => {
      navigate('/dashboard');
    }, 10000); // 10 seconds to allow more time for the subscription check and update
    return () => clearTimeout(timer);
  }, [navigate, auth]);

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-black">
      <div className="text-center p-10 rounded-lg bg-black border border-white border-opacity-20 backdrop-blur-md">
        <h1 className="text-3xl font-extrabold text-white mb-4">Welcome to Klorah Pro</h1>
        <p className="text-lg text-white mb-6">Your payment was successful</p>
        {isUpdating && <p className="text-white mb-4">Updating your subscription...</p>}
        {error && <p className="text-red-500 mb-4">{error}</p>}
        <button 
          className="px-6 py-3 bg-white text-black rounded-lg shadow-lg hover:bg-gray-200 transition-all duration-300" 
          onClick={() => navigate('/dashboard')}
        >
          Go to Dashboard
        </button>
      </div>
    </div>
  );
}
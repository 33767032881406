import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc, updateDoc, setDoc, query, where, getDocs, collection, deleteDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { auth, db, storage } from '../Firebase';
import NavigationBar from '../NavigationBar';
import ProfileHeader from './ProfileHeader';
import ServiceList from './ServiceList';
import SkillList from './SkillList';
import ContactInfo from './ContactInfo';
import SocialMediaLinks from './SocialMediaLinks';
import Portfolio from './Portfolio';
import ServiceDetailsWithBookingForm from './ServiceDetailsWithBookingForm';
import { toast } from 'react-toastify';

const ProfileView = () => {
  const [profile, setProfile] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const { username } = useParams();
  const navigate = useNavigate();
  const [usernameAvailable, setUsernameAvailable] = useState(true);
  const [newService, setNewService] = useState({ name: '', description: '', price: '', currency: 'USD' });
  const [hoveredServiceIndex, setHoveredServiceIndex] = useState(null);
  const [selectedService, setSelectedService] = useState(null);
  const [deleteConfirmation, setDeleteConfirmation] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  useEffect(() => {
    const fetchProfile = async () => {
      if (username) {
        const profilesRef = collection(db, 'profiles');
        const q = query(profilesRef, where('username', '==', username));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const profileData = querySnapshot.docs[0].data();
          setProfile({ ...profileData, userId: querySnapshot.docs[0].id });
        } else {
          console.log('Profile not found');
        }
      } else {
        const unsubscribe = auth.onAuthStateChanged(async (user) => {
          if (user) {
            setIsAuthenticated(true);
            const profileRef = doc(db, 'profiles', user.uid);
            const profileSnapshot = await getDoc(profileRef);

            if (profileSnapshot.exists()) {
              setProfile({ ...profileSnapshot.data(), userId: user.uid });
            } else {
              const newProfile = {
                bio: '',
                name: '',
                profilePicture: '',
                services: [],
                username: '',
                professionalTitle: '',
                skills: [],
                socialMediaLinks: {},
                personalWebsite: ''
              };
              await setDoc(profileRef, newProfile);
              setProfile({ ...newProfile, userId: user.uid });
              navigate('/edit-profile'); // Navigate to EditProfile on first load
            }
          } else {
            navigate('/login');
          }
        });

        return () => unsubscribe();
      }
    };

    fetchProfile();
  }, [username, navigate]);

  const updateProfile = async (updates) => {
    if (updates.username && updates.username !== profile.username) {
      const isUnique = await checkUsernameUnique(updates.username);
      if (!isUnique) {
        setUsernameAvailable(false);
        return;
      }
    }

    const profileRef = doc(db, 'profiles', profile.userId);
    await updateDoc(profileRef, updates);
    setProfile((prev) => ({ ...prev, ...updates }));
    setUsernameAvailable(true);
  };

  const checkUsernameUnique = async (username) => {
    const usersRef = collection(db, 'profiles');
    const q = query(usersRef, where('username', '==', username));
    const querySnapshot = await getDocs(q);
    return querySnapshot.empty;
  };

  const handleEditProfile = () => {
    navigate('/edit-profile'); // Navigate to EditProfile
  };

  const handleAddService = async () => {
    const profileRef = doc(db, 'profiles', profile.userId);
    await updateDoc(profileRef, { services: [...profile.services, newService] });
    setProfile((prev) => ({ ...prev, services: [...prev.services, newService] }));
  };

  const deleteService = async (serviceToDelete) => {
    if (window.confirm('Are you sure you want to delete this service?')) {
      const updatedServices = profile.services.filter((service) => service !== serviceToDelete);
      const profileRef = doc(db, 'profiles', profile.userId);
      await updateDoc(profileRef, { services: updatedServices });
      setProfile((prev) => ({ ...prev, services: updatedServices }));

      if (serviceToDelete.image) {
        const storageRef = ref(storage, serviceToDelete.image);
        await deleteObject(storageRef);
      }
    }
  };

  const handleProfilePictureChange = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const storageRef = ref(storage, `profilePictures/${profile.userId}`);
    await uploadBytes(storageRef, file);
    const photoURL = await getDownloadURL(storageRef);

    updateProfile({ profilePicture: photoURL });
  };

  const handleServiceImageUpload = async (e, service) => {
    const file = e.target.files[0];
    if (!file) return;

    const storageRef = ref(storage, `serviceImages/${service.name}_${profile.userId}`);
    await uploadBytes(storageRef, file);
    const imageUrl = await getDownloadURL(storageRef);

    const updatedServices = profile.services.map((s) => {
      if (s === service) {
        return { ...s, image: imageUrl };
      }
      return s;
    });

    const profileRef = doc(db, 'profiles', profile.userId);
    await updateDoc(profileRef, { services: updatedServices });
    setProfile((prev) => ({ ...prev, services: updatedServices }));
  };

  const handleServiceClick = (service) => {
    setSelectedService({ ...service, freelancerEmail: profile.contactInfo?.email });
  };

  const handleSignOut = async () => {
    try {
      await auth.signOut();
      navigate('/');
    } catch (error) {
      console.error('Error signing out: ', error);
    }
  };

  const handleDeleteAccount = async () => {
    if (deleteConfirmation === 'i want to delete') {
      try {
        const profileRef = doc(db, 'profiles', profile.userId);
        await deleteDoc(profileRef);
        await auth.currentUser.delete();
        navigate('/');
      } catch (error) {
        console.error('Error deleting account: ', error);
      }
    } else {
      toast.error('Please type the confirmation text correctly.');
    }
  };

  if (!profile) {
    return <div className="text-black dark:text-white min-h-screen flex justify-center items-center">Loading...</div>;
  }

  return (
    <div className="dark:bg-black text-gray-900 dark:text-gray-100 min-h-screen">
      <div className="sticky top-0 z-50">
        {isAuthenticated && <NavigationBar />}
      </div>
      <div className="container mx-auto px-4 py-8">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
          <div className="lg:col-span-1">
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
              <ProfileHeader
                profile={profile}
                handleEditProfile={isAuthenticated ? handleEditProfile : undefined}
                handleProfilePictureChange={isAuthenticated ? handleProfilePictureChange : undefined}
                isAuthenticated={isAuthenticated}
              />
              {(profile.email || profile.phone || profile.location) && (
                <div className="mt-6">
                  <ContactInfo profile={profile} />
                </div>
              )}
              {profile.socialMediaLinks && Object.values(profile.socialMediaLinks).some(link => link) && (
                <div className="mt-6">
                  <SocialMediaLinks socialMediaLinks={profile.socialMediaLinks} />
                </div>
              )}
            </div>
          </div>
          <div className="lg:col-span-2">
            {profile.bio && (
              <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6 mb-8">
                <h3 className="text-2xl font-bold mb-4">About</h3>
                <p className="text-gray-600 dark:text-gray-400">{profile.bio}</p>
              </div>
            )}
            {profile.skills && profile.skills.length > 0 && (
              <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6 mb-8">
                <h3 className="text-2xl font-bold mb-4">Skills</h3>
                <SkillList skills={profile.skills} />
              </div>
            )}
            {profile.services && profile.services.length > 0 && (
              <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6 mb-8">
                <h3 className="text-2xl font-bold mb-4">Services</h3>
                <ServiceList
                  services={profile.services}
                  handleAddService={isAuthenticated ? handleAddService : undefined}
                  handleServiceClick={handleServiceClick}
                  deleteService={isAuthenticated ? deleteService : undefined}
                  handleServiceImageUpload={isAuthenticated ? handleServiceImageUpload : undefined}
                  hoveredServiceIndex={hoveredServiceIndex}
                  setHoveredServiceIndex={setHoveredServiceIndex}
                  isAuthenticated={isAuthenticated}
                />
              </div>
            )}
          </div>
        </div>
        
        {profile.portfolio && profile.portfolio.length > 0 && (
          <div className="mt-12">
            <h3 className="text-2xl font-bold mb-6">Portfolio</h3>
            <Portfolio portfolio={profile.portfolio} />
          </div>
        )}
        
        {isAuthenticated && (
          <div className="mt-12 flex justify-center space-x-4">
            <button
              className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded-lg transition duration-300"
              onClick={handleSignOut}
            >
              Sign out
            </button>
            <button
              className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded-lg transition duration-300"
              onClick={() => setShowDeleteModal(true)}
            >
              Delete Account
            </button>
          </div>
        )}
      </div>

      {/* Modals */}
      {selectedService && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white dark:bg-gray-800 p-6 rounded-lg max-w-2xl w-full">
            <ServiceDetailsWithBookingForm
              service={selectedService}
              freelancerEmail={selectedService.freelancerEmail}
              closeModal={() => setSelectedService(null)}
            />
          </div>
        </div>
      )}

      {showDeleteModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white dark:bg-gray-800 p-6 rounded-lg max-w-md w-full">
            <h2 className="text-2xl font-bold mb-4">Confirm Account Deletion</h2>
            <p className="mb-4">Please type "i want to delete" to confirm.</p>
            <input
              type="text"
              value={deleteConfirmation}
              onChange={(e) => setDeleteConfirmation(e.target.value)}
              className="w-full p-2 border rounded-lg mb-4"
            />
            <div className="flex justify-end space-x-4">
              <button
                className="px-4 py-2 bg-gray-300 rounded-lg"
                onClick={() => setShowDeleteModal(false)}
              >
                Cancel
              </button>
              {/* <button
                className="px-4 py-2 bg-red-500 text-white rounded-lg"
                onClick={handleDeleteAccount}
                disabled={deleteConfirmation !== 'i want to delete'}
              >
                Delete Account
              </button> */}
            </div>
          </div>
        </div>
      )}
      <div className="text-center mt-8">
        <p className="text-gray-500 dark:text-gray-400">
          Powered by <a href="https://klorah.app" className="text-green-500 dark:text-green-400 hover:underline">Klorah</a>
        </p>
      </div>
    </div>
  );
};

export default ProfileView;

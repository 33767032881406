import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import {
  ChevronLeft,
  ChevronRight,
  Calendar,
  Trophy,
  Flame,
  Zap,
  Lightbulb,
  LineChart,
  CheckCircle2,
  Circle,
  ArrowRight,
  ChevronDown,
  Send,
} from 'lucide-react';
import NavigationBar from '../NavigationBar';
import ProfileHeader from '../Profile/ProfileHeader';
import ProfileSuggestions from './ProfileSuggestions';
import { getAuth } from 'firebase/auth';
import { getFirestore, doc, getDoc, updateDoc, setDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { initialTasks } from './InitialTasks';
import OutreachForm from './OutreachForm';
import FeedbackButtonWithForm from '../FeedbackButtonWithForm';

const generatePersonalizedTasks = async (challengeAnswers) => {
  try {
    const response = await fetch('https://klorah-fast-server-9266fe8d441a.herokuapp.com/generate-tasks', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ challengeAnswers }),
    });

    if (!response.ok) {
      throw new Error('Failed to generate personalized tasks');
    }

    const data = await response.json();
    return Array.isArray(data.tasks) ? data.tasks : [];
  } catch (error) {
    console.error('Error generating personalized tasks:', error);
    return [];
  }
};

const DailyTip = ({ tip }) => (
  <div className="bg-white dark:bg-gray-800 border border-orange-500/20 rounded-lg p-4 mt-6 pb-4">
    <h4 className="text-lg font-semibold text-orange-500 mb-2 flex items-center">
      <Lightbulb className="mr-2 h-5 w-5" />
      Daily Tip
    </h4>
    <p className="text-gray-800 dark:text-gray-300">{tip}</p>
  </div>
);

const ProgressChart = ({ completedTasks, totalTasks }) => (
  <div className="mt-6">
    <h4 className="text-lg font-semibold text-orange-500 mb-2 flex items-center">
      <LineChart className="mr-2 h-5 w-5" />
      Progress Chart
    </h4>
    <div className="bg-gray-300 dark:bg-gray-700 h-3 rounded-full overflow-hidden">
      <div 
        className="bg-orange-500 h-full rounded-full transition-all duration-500 ease-out"
        style={{width: `${(completedTasks / totalTasks) * 100}%`}}
      ></div>
    </div>
    <div className="flex justify-between text-xs text-gray-600 dark:text-gray-400 mt-1">
      <span>0%</span>
      <span>50%</span>
      <span>100%</span>
    </div>
  </div>
);

export default function ChallengeDashboard() {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [startDate, setStartDate] = useState(null);
  const [tasks, setTasks] = useState(initialTasks);
  const [completedTasks, setCompletedTasks] = useState({});
  const [streakDays, setStreakDays] = useState(0);
  const [dailyTip, setDailyTip] = useState("");
  const [profile, setProfile] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isSuggestionsExpanded, setIsSuggestionsExpanded] = useState(false);
  const [isGeneratingTasks, setIsGeneratingTasks] = useState(false);
  const navigate = useNavigate();
  const auth = getAuth();
  const db = getFirestore();

  useEffect(() => {
    const checkChallengeStatus = async () => {
      const user = auth.currentUser;
      if (user) {
        const challengeRef = doc(db, 'challengeProgress', user.uid);
        const challengeDoc = await getDoc(challengeRef);
        
        if (!challengeDoc.exists()) {
          navigate('/challenge-setup');
          return;
        }
        
        const challengeData = challengeDoc.data();
        setStartDate(challengeData.startDate.toDate());
        setCompletedTasks(challengeData.completedTasks || {});
        setStreakDays(challengeData.streakDays || 0);
        setDailyTip(challengeData.dailyTip || "Consistency is key. Try to complete at least one task every day to maintain your streak!");
        
        const profileRef = doc(db, 'profiles', user.uid);
        const profileDoc = await getDoc(profileRef);
        if (profileDoc.exists()) {
          setProfile(profileDoc.data());
        }

        // Check if personalized tasks have been generated
        if (!challengeData.personalizedTasksGenerated) {
          setIsGeneratingTasks(true);
          // Fetch challenge answers
          const answersRef = doc(db, 'challengeAnswers', user.uid);
          const answersDoc = await getDoc(answersRef);
          
          if (answersDoc.exists()) {
            const answers = answersDoc.data().questionAnswerPairs;
            
            // Generate personalized tasks
            const personalizedTasks = await generatePersonalizedTasks(answers);
            
            if (personalizedTasks && Array.isArray(personalizedTasks)) {
              // Merge personalized tasks with initial tasks
              const mergedTasks = initialTasks.map((day, index) => {
                const personalizedTask = personalizedTasks[index];
                return {
                  ...day,
                  tasks: [
                    ...day.tasks,
                    personalizedTask ? { 
                      id: `personalized-${day.day}`, 
                      text: personalizedTask.text, 
                      category: "Personalized" 
                    } : null
                  ].filter(Boolean)
                };
              });
              
              setTasks(mergedTasks);
              
              // Update Firestore with merged tasks and set flag
              await updateDoc(challengeRef, {
                tasks: mergedTasks,
                personalizedTasksGenerated: true,
              });
            } else {
              console.error('Invalid personalized tasks format:', personalizedTasks);
              setTasks(initialTasks);
            }
          } else {
            setTasks(initialTasks);
          }
          setIsGeneratingTasks(false);
        } else {
          setTasks(challengeData.tasks || initialTasks);
        }
        
        setIsLoading(false);
      } else {
        navigate('/login');
      }
    };

    checkChallengeStatus();
  }, [auth, db, navigate]);

  const updateFirebase = async (updatedData) => {
    const user = auth.currentUser;
    if (user) {
      const challengeRef = doc(db, 'challengeProgress', user.uid);
      await updateDoc(challengeRef, {
        ...updatedData,
        tasks: tasks,
      });
    }
  };

  const toggleTask = async (taskId) => {
    setCompletedTasks(prev => {
      const newCompletedTasks = { ...prev };
      if (newCompletedTasks[taskId]) {
        delete newCompletedTasks[taskId];
      } else {
        newCompletedTasks[taskId] = true;
      }
      updateFirebase({ completedTasks: newCompletedTasks });
      return newCompletedTasks;
    });

    setTasks(prevTasks => 
      prevTasks.map(day => ({
        ...day,
        tasks: day.tasks.map(task => 
          task.id === taskId ? { ...task, completed: !task.completed } : task
        )
      }))
    );
  };

  const changeDate = (days) => {
    const newDate = new Date(currentDate);
    newDate.setDate(newDate.getDate() + days);
    setCurrentDate(newDate);
  };

  const getDateString = (date) => {
    return date.toLocaleDateString('en-US', { weekday: 'long', month: 'long', day: 'numeric' });
  };

  const getCurrentDay = () => {
    if (!startDate) return 1;
    const diffTime = Math.abs(currentDate - startDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return Math.min(diffDays, 30);
  };

  const getCurrentTasks = () => {
    const currentDay = getCurrentDay();
    return tasks.find(t => t.day === currentDay)?.tasks.filter(task => task.text.trim() !== "") || [];
  };

  const getTotalCompletedTasks = () => {
    return Object.keys(completedTasks).length;
  };

  const getTotalTasks = () => {
    return tasks.reduce((total, day) => total + day.tasks.length, 0);
  };

  if (isLoading) {
    return (
      <div className="min-h-screen bg-gray-100 dark:bg-black flex items-center justify-center">
        {isGeneratingTasks ? (
          <div className="text-center">
            <p className="text-black dark:text-white text-2xl mb-4">Generating your personalized tasks...</p>
            <div className="flex justify-center">
              <motion.div
                animate={{ rotate: 360 }}
                transition={{ repeat: Infinity, duration: 1 }}
                className="h-16 w-16 border-4 border-t-4 border-t-orange-500 border-gray-200 dark:border-gray-700 rounded-full"
              ></motion.div>
            </div>
          </div>
        ) : (
          <p className="text-black dark:text-white text-2xl">Loading...</p>
        )}
      </div>
    );
  }

  return (
    <div className="min-h-screen dark:bg-black text-black dark:text-white">
      <div className="sticky top-0 z-50">
        <NavigationBar isTransparent={false} />
      </div>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <motion.h1
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-4xl md:text-5xl font-bold mb-12 text-center text-orange-500"
        >
          30-Day Klorah Creator Challenge
        </motion.h1>
  
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {/* Profile Header */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="bg-white dark:bg-gray-800 rounded-xl shadow-xl p-6"
          >
            <ProfileHeader
              profile={profile}
              handleEditProfile={() => navigate('/edit-profile')}
              handleProfilePictureChange={() => {/* Implement profile picture change logic */}}
              isAuthenticated={true}
            />
            <div className="mt-6">
              <button
                onClick={() => setIsSuggestionsExpanded(!isSuggestionsExpanded)}
                className="flex items-center justify-between w-full text-left text-orange-500 hover:text-orange-400"
              >
                <span>Profile Suggestions</span>
                <ChevronDown className={`transform transition-transform ${isSuggestionsExpanded ? 'rotate-180' : ''}`} />
              </button>
              <AnimatePresence>
                {isSuggestionsExpanded && (
                  <motion.div
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: 'auto' }}
                    exit={{ opacity: 0, height: 0 }}
                    transition={{ duration: 0.3 }}
                  >
                    <ProfileSuggestions profile={profile} />
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </motion.div>
  
          {/* Progress */}
          <motion.div
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            className="bg-white dark:bg-gray-800 rounded-xl shadow-xl p-6"
          >
            <h3 className="text-xl font-semibold mb-4 text-orange-500">Your Progress</h3>
            <div className="flex items-center mb-4 bg-orange-100 dark:bg-gray-700 p-3 rounded-lg">
              <Flame className="h-6 w-6 text-orange-500 mr-2" />
              <div>
                <p className="text-lg font-semibold text-black dark:text-white">Current streak: {streakDays} days</p>
              </div>
            </div>
            <div className="flex items-center mb-4 bg-orange-100 dark:bg-gray-700 p-3 rounded-lg">
              <Trophy className="h-6 w-6 text-orange-500 mr-2" />
              <div>
                <p className="text-lg font-semibold text-black dark:text-white">Tasks completed: {getTotalCompletedTasks()} / {getTotalTasks()}</p>
              </div>
            </div>
  
            <ProgressChart completedTasks={getTotalCompletedTasks()} totalTasks={getTotalTasks()} />
  
            <h3 className="text-xl font-semibold mb-4 mt-4 text-orange-500">Challenge Overview</h3>
            <p className="text-lg font-semibold text-black dark:text-white mb-4">Day {getCurrentDay()} of 30</p>
            <div className="grid grid-cols-6 gap-2">
              {Array.from({ length: 30 }, (_, i) => (
                <div
                  key={i}
                  className={`w-full pt-full rounded-full ${i < getCurrentDay() ? 'bg-orange-500' : 'bg-gray-300 dark:bg-gray-600'} transition-all duration-300 ease-in-out relative shadow-lg`}
                >
                  {i < getCurrentDay() && (
                    <Zap
                      className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-black dark:text-white h-3 w-3"
                    />
                  )}
                </div>
              ))}
            </div>
          </motion.div>
  
          {/* Daily Tasks */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="bg-white dark:bg-gray-800 rounded-xl shadow-xl p-6 md:col-span-2"
          >
            <div className="flex justify-between items-center mb-6">
              <button onClick={() => changeDate(-1)} className="text-orange-500 hover:text-orange-400 transition-colors">
                <ChevronLeft className="h-6 w-6" />
              </button>
              <h2 className="text-xl font-semibold flex items-center">
                <Calendar className="mr-2 h-5 w-5 text-orange-500" />
                {getDateString(currentDate)}
              </h2>
              <button onClick={() => changeDate(1)} className="text-orange-500 hover:text-orange-400 transition-colors">
                <ChevronRight className="h-6 w-6" />
              </button>
            </div>
  
            <DailyTip tip={dailyTip} />
  
            <h3 className="text-xl font-semibold mb-4 mt-4 text-orange-500">Today's Tasks</h3>
            <motion.div layout>
              {getCurrentTasks().map((task) => (
                <motion.div
                  key={task.id}
                  layout
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  transition={{ duration: 0.3 }}
                  className="flex items-center mb-4 bg-white dark:bg-gray-700 p-3 rounded-lg hover:bg-orange-100 dark:hover:bg-gray-600 transition-colors shadow-md"
                >
                  <button
                    onClick={() => toggleTask(task.id)}
                    className={`mr-3 text-2xl ${completedTasks[task.id] ? 'text-orange-500' : 'text-gray-500'}`}
                  >
                    {completedTasks[task.id] ? <CheckCircle2 className="h-6 w-6" /> : <Circle className="h-6 w-6" />}
                  </button>
                  <span className={`text-base ${completedTasks[task.id] ? 'line-through text-gray-400' : ''}`}>
                    {task.text}
                  </span>
                  <span className="ml-auto text-xs px-2 py-1 bg-orange-500/20 text-orange-300 rounded-full">{task.category}</span>
                  {task.category === "Profile" && (
                    <button
                      onClick={() => navigate('/profile')}
                      className="ml-2 text-orange-500 hover:text-orange-400 transition-colors"
                    >
                      <ArrowRight className="h-5 w-5" />
                    </button>
                  )}
                </motion.div>
              ))}
            </motion.div>
          </motion.div>

          {/* Outreach Message Generator
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="bg-orange-50 dark:bg-gray-750 rounded-xl shadow-xl p-6 md:col-span-2 mt-8"
          >
            <h2 className="text-2xl font-semibold mb-6 text-orange-500 flex items-center">
              <Send className="mr-2 h-6 w-6" />
              Outreach Message Generator
            </h2>
            <OutreachForm />
          </motion.div> */}
          <FeedbackButtonWithForm />
        </div>
      </div>
    </div>
  );
}
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faTwitter, faLinkedin, faTiktok } from '@fortawesome/free-brands-svg-icons';

export default function Footer() {
  return (
    <footer className="py-12 bg-black text-gray-400">
      <div className="container mx-auto px-8 flex flex-col items-center justify-center space-y-8">
        <img
          className="h-8 w-auto"
          src="/klorahLogo.png"
          alt="Klorah"
        />
        <nav className="flex flex-wrap justify-center items-center gap-8">
          <a href="https://www.instagram.com/klorahapp/?hl=en" target="_blank" rel="noopener noreferrer" className="flex items-center space-x-2 hover:underline">
            <FontAwesomeIcon icon={faInstagram} size="lg" />
            <span>Instagram</span>
          </a>
          <a href="https://x.com/klorahapp" target="_blank" rel="noopener noreferrer" className="flex items-center space-x-2 hover:underline">
            <FontAwesomeIcon icon={faTwitter} size="lg" />
            <span>Twitter</span>
          </a>
          <a href="https://www.linkedin.com/company/klorah/" target="_blank" rel="noopener noreferrer" className="flex items-center space-x-2 hover:underline">
            <FontAwesomeIcon icon={faLinkedin} size="lg" />
            <span>LinkedIn</span>
          </a>
          <a href="https://www.tiktok.com/@klorah" target="_blank" rel="noopener noreferrer" className="flex items-center space-x-2 hover:underline">
            <FontAwesomeIcon icon={faTiktok} size="lg" />
            <span>TikTok</span>
          </a>
        </nav>
        <div className="text-center max-w-2xl">
          <h3 className="text-xl font-bold mb-2">About Klorah</h3>
          <p className="text-base">Located in the heart of New York City, Klorah is committed to creating innovative financial solutions for freelancers. Our mission is to equip you with the tools you need to thrive in the gig economy.</p>
        </div>
        <div className="text-center">
          <p>&copy; {new Date().getFullYear()} Klorah, Inc. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
}
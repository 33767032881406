// these are the initial/default tasks assigned to everyone who takes the challenge
export const initialTasks = [
    { 
      day: 1, 
      tasks: [
        { id: '1a', text: "Set up your Klorah profile", category: "Profile" },
        { id: '1b', text: "Write a compelling bio", category: "Profile" }
      ]
    },
    { 
      day: 2, 
      tasks: [
        { id: '2a', text: "Define your ideal client persona", category: "Strategy" },
        { id: '2b', text: "List your top 5 services", category: "Strategy" }
      ]
    },
    { 
      day: 3, 
      tasks: [
        { id: '3a', text: "Create a portfolio of your best work", category: "Profile" },
        { id: '3b', text: "Set up a professional email address", category: "Profile" }
      ]
    },
    {
      day: 4,
      tasks: [
        { id: '4a', text: "Research 10 potential clients", category: "Strategy" },
        { id: '4b', text: "Create a spreadsheet to track client leads", category: "Organization" }
      ]
    },
    {
      day: 5,
      tasks: [
        { id: '5a', text: "Draft your first cold email template", category: "Outreach" },
        { id: '5b', text: "Set up an email tracking tool", category: "Tools" }
      ]
    },
    {
      day: 6,
      tasks: [
        { id: '6a', text: "Set up a professional social media presence", category: "Profile" },
        { id: '6b', text: "Create a content calendar for the next week", category: "Marketing" }
      ]
    },
    {
      day: 7,
      tasks: [
        { id: '7a', text: "Define your unique selling proposition (USP)", category: "Strategy" },
        { id: '7b', text: "Write a elevator pitch for your services", category: "Marketing" }
      ]
    },
    {
      day: 8,
      tasks: [
        { id: '8a', text: "Reach out to a past client for a testimonial", category: "Outreach" },
        { id: '8b', text: "Create a testimonial section on your Klorah profile", category: "Profile" }
      ]
    },
    {
      day: 9,
      tasks: [
        { id: '9a', text: "Write a blog post showcasing your expertise", category: "Content" },
        { id: '9b', text: "Share your blog post on social media", category: "Marketing" }
      ]
    },
    {
      day: 10,
      tasks: [
        { id: '10a', text: "Attend a networking event or webinar", category: "Networking" },
        { id: '10b', text: "Follow up with 3 new contacts from the event", category: "Outreach" }
      ]
    },
    {
      day: 11,
      tasks: [
        { id: '11a', text: "Create a professional email signature", category: "Profile" },
        { id: '11b', text: "Set up email templates for common responses", category: "Organization" }
      ]
    },
    {
      day: 12,
      tasks: [
        { id: '12a', text: "Set up a project management system", category: "Organization" },
        { id: '12b', text: "Create templates for project proposals", category: "Business" }
      ]
    },
    {
      day: 13,
      tasks: [
        { id: '13a', text: "Write 5 testimonial request emails", category: "Outreach" },
        { id: '13b', text: "Update your portfolio with recent work", category: "Profile" }
      ]
    },
    {
      day: 14,
      tasks: [
        { id: '14a', text: "Analyze your competitors' strategies", category: "Strategy" },
        { id: '14b', text: "Identify 3 ways to differentiate your services", category: "Strategy" }
      ]
    },
    {
      day: 15,
      tasks: [
        { id: '15a', text: "Create a freelance rate calculator", category: "Finance" },
        { id: '15b', text: "Set your pricing strategy for different services", category: "Finance" }
      ]
    },
    {
      day: 16,
      tasks: [
        { id: '16a', text: "Write a case study on a successful project", category: "Content" },
        { id: '16b', text: "Add the case study to your Klorah profile", category: "Profile" }
      ]
    },
    {
      day: 17,
      tasks: [
        { id: '17a', text: "Optimize your LinkedIn profile", category: "Profile" },
        { id: '17b', text: "Connect with 10 potential clients on LinkedIn", category: "Networking" }
      ]
    },
    {
      day: 18,
      tasks: [
        { id: '18a', text: "Create a lead magnet for your target audience", category: "Marketing" },
        { id: '18b', text: "Set up a landing page for your lead magnet", category: "Marketing" }
      ]
    },
    {
      day: 19,
      tasks: [
        { id: '19a', text: "Set up automated invoicing system", category: "Finance" },
        { id: '19b', text: "Create a template for project contracts", category: "Business" }
      ]
    },
    {
      day: 20,
      tasks: [
        { id: '20a', text: "Reach out to 5 new potential clients", category: "Outreach" },
        { id: '20b', text: "Follow up with previous leads", category: "Outreach" }
      ]
    },
    {
      day: 21,
      tasks: [
        { id: '21a', text: "Create a client onboarding process", category: "Organization" },
        { id: '21b', text: "Design a welcome packet for new clients", category: "Business" }
      ]
    },
    {
      day: 22,
      tasks: [
        { id: '22a', text: "Learn a new skill relevant to your niche", category: "Skill Development" },
        { id: '22b', text: "Update your skills section on your Klorah profile", category: "Profile" }
      ]
    },
    {
      day: 23,
      tasks: [
        { id: '23a', text: "Create a professional proposal template", category: "Business" },
        { id: '23b', text: "Write a proposal for a dream client", category: "Outreach" }
      ]
    },
    {
      day: 24,
      tasks: [
        { id: '24a', text: "Set up a referral program", category: "Marketing" },
        { id: '24b', text: "Reach out to 3 satisfied clients about referrals", category: "Outreach" }
      ]
    },
    {
      day: 25,
      tasks: [
        { id: '25a', text: "Conduct a SWOT analysis of your freelance business", category: "Strategy" },
        { id: '25b', text: "Identify 3 areas for improvement in your business", category: "Strategy" }
      ]
    },
    {
      day: 26,
      tasks: [
        { id: '26a', text: "Create a FAQ page for your services", category: "Content" },
        { id: '26b', text: "Add the FAQ to your Klorah profile", category: "Profile" }
      ]
    },
    {
      day: 27,
      tasks: [
        { id: '27a', text: "Set up a system for tracking your time", category: "Organization" },
        { id: '27b', text: "Analyze your productivity and identify time-wasters", category: "Strategy" }
      ]
    },
    {
      day: 28,
      tasks: [
        { id: '28a', text: "Plan a collaborative project with another freelancer", category: "Networking" },
        { id: '28b', text: "Reach out to potential collaboration partners", category: "Outreach" }
      ]
    },
    {
      day: 29,
      tasks: [
        { id: '29a', text: "Create a 90-day plan for your freelance business", category: "Strategy" },
        { id: '29b', text: "Set SMART goals for the next quarter", category: "Strategy" }
      ]
    },
    {
      day: 30,
      tasks: [
        { id: '30a', text: "Review and update your Klorah profile", category: "Profile" },
        { id: '30b', text: "Celebrate your progress and plan your next steps!", category: "Reflection" }
      ]
    }
  ];
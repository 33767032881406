import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash, faEdit } from '@fortawesome/free-solid-svg-icons';
import ServiceModal from './ServiceModal';
import ServiceDetailsWithBookingForm from './ServiceDetailsWithBookingForm';

const ServiceList = ({
  services,
  handleAddService,
  handleUpdateService,
  handleDeleteService,
  handleServiceImageUpload,
  isAuthenticated,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [hoveredService, setHoveredService] = useState(null);
  const [editingService, setEditingService] = useState(null);
  const [selectedService, setSelectedService] = useState(null);

  const openModal = (service = null) => {
    setEditingService(service);
    setShowModal(true);
  };

  const closeModal = () => {
    setEditingService(null);
    setShowModal(false);
  };

  const openBookingModal = (service) => {
    setSelectedService(service);
  };

  const closeBookingModal = () => {
    setSelectedService(null);
  };

  const saveService = (service) => {
    if (editingService) {
      handleUpdateService(editingService.id, service);
    } else {
      handleAddService(service);
    }
    closeModal();
  };

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 w-full mt-4 mx-auto">
      {services.map((service) => (
        <div
          key={service.id}
          className={`relative overflow-hidden rounded-xl transition-shadow duration-200 ${
            hoveredService === service.id ? 'shadow-lg' : ''
          }`}
          onMouseEnter={() => setHoveredService(service.id)}
          onMouseLeave={() => setHoveredService(null)}
          onClick={() => openBookingModal(service)}
        >
          <div className="w-full h-48 flex items-center justify-center overflow-hidden rounded-xl">
            {service.image ? (
              <img
                src={service.image}
                alt={service.name}
                className="w-full h-full object-cover rounded-xl"
              />
            ) : (
              <FontAwesomeIcon
                icon={faPlus}
                size="2x"
                className="text-gray-400 dark:text-gray-500"
              />
            )}
            {isAuthenticated && hoveredService === service.id && (
              <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 transition-opacity duration-200 rounded-xl">
                <label
                  htmlFor={`service-image-${service.id}`}
                  className="cursor-pointer"
                  onClick={(e) => e.stopPropagation()}
                >
                  <FontAwesomeIcon icon={faPlus} size="2x" className="text-white" />
                </label>
                <input
                  id={`service-image-${service.id}`}
                  type="file"
                  accept="image/*"
                  className="hidden"
                  onChange={(e) => handleServiceImageUpload(e, service)}
                />
              </div>
            )}
          </div>
          <div className="mt-4 p-4">
            <h3 className="font-bold text-lg dark:text-gray-300">{service.name}</h3>
            <p className="dark:text-gray-400">{service.description}</p>
            <p className="font-bold dark:text-gray-200">
              ${service.price} {service.currency}
            </p>
          </div>
          {isAuthenticated && hoveredService === service.id && (
            <div className="absolute top-2 right-2 flex space-x-2">
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  openModal(service);
                }}
                className="p-2 bg-black text-white rounded hover:bg-white hover:text-black transition-colors duration-200"
              >
                <FontAwesomeIcon icon={faEdit} />
              </button>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  handleDeleteService(service.id);
                }}
                className="p-2 bg-black text-white rounded hover:bg-white hover:text-black transition-colors duration-200"
              >
                <FontAwesomeIcon icon={faTrash} />
              </button>
            </div>
          )}
        </div>
      ))}
      {isAuthenticated && (
        <div
          className="rounded-xl border-2 border-dashed border-gray-300 dark:border-gray-500 p-6 flex flex-col items-center justify-center cursor-pointer text-center hover:bg-gray-50 dark:hover:bg-gray-700 transition-colors duration-200"
          onClick={() => openModal()}
        >
          <FontAwesomeIcon
            icon={faPlus}
            size="2x"
            className="text-gray-400 dark:text-gray-500 mb-2"
          />
          <span className="text-sm font-semibold text-gray-500 dark:text-gray-400">
            Add Service
          </span>
        </div>
      )}
      {showModal && (
        <ServiceModal
          service={editingService}
          onSave={saveService}
          onClose={closeModal}
        />
      )}
      {selectedService && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white dark:bg-gray-800 p-6 rounded-lg max-w-lg w-full">
            <ServiceDetailsWithBookingForm
              service={selectedService}
              closeModal={closeBookingModal}
              freelancerEmail={selectedService.freelancerEmail}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ServiceList;

import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';

const ProfileHeader = ({ profile, handleEditProfile, handleProfilePictureChange, isAuthenticated }) => {
  const { username } = useParams();
  const navigate = useNavigate();

  const handleShareProfile = () => {
    const shareableLink = `https://www.klorah.app/profile/${profile.username}`;
    // Create a temporary input element
    const tempInput = document.createElement('input');
    tempInput.value = shareableLink;
    
    // Append the temporary input element to the document body
    document.body.appendChild(tempInput);
    
    // Select the text in the temporary input element
    tempInput.select();
    
    // Execute the copy command
    document.execCommand('copy');
    
    // Remove the temporary input element
    document.body.removeChild(tempInput);
    
    alert('Profile link copied to clipboard!');
  };

  return (
    <div className="flex flex-col items-center space-y-4">
      <div className="w-32 h-32 rounded-full bg-gray-200 dark:bg-gray-700 mb-1 flex items-center justify-center overflow-hidden relative">
        {profile.profilePicture ? (
          <img src={profile.profilePicture} alt="Profile" className="w-full h-full object-cover" />
        ) : (
          <FontAwesomeIcon icon={faUser} size="4x" className="text-gray-500 dark:text-gray-300" />
        )}
        {isAuthenticated && (
          <input type="file" accept="image/*" onChange={handleProfilePictureChange} className="absolute inset-0 opacity-0 cursor-pointer" />
        )}
      </div>
      <h2 className="text-3xl font-bold text-gray-800 dark:text-gray-200 mb-0">{profile.name}</h2>
      <p className="text-lg text-gray-500 dark:text-gray-400 mt-1">@{profile.username}</p>
      <p className="mt-1 text-md text-gray-600 dark:text-gray-300">{profile.professionalTitle}</p>
      {/* <p className="mt-2 text-sm text-gray-600 dark:text-gray-300">{profile.bio}</p> */}
      {isAuthenticated && (
        <button className="mt-2 text-md text-gray-500 dark:text-gray-400 hover:text-gray-600 dark:hover:text-gray-300" onClick={handleEditProfile}>
          Edit Profile
        </button>
      )}
      {profile.username && (
        <button className="bg-black hover:bg-green-600 text-white font-bold py-2 px-4 rounded-xl mt-4 dark:bg-white dark:text-black" onClick={handleShareProfile}>
          Share Profile
        </button>
      )}
    </div>
  );
};

export default ProfileHeader;
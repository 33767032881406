import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export default function CreatorChallengeOnboarding() {
  const [currentStep, setCurrentStep] = useState(1); // Track the current step in the onboarding process
  const [formData, setFormData] = useState({
    profile: { name: '', username: '', bio: '', services: [] },
    clientInfo: { pastClients: false, contactWithClients: false, testimonials: '', servicesOffered: '' },
  });
  const navigate = useNavigate();

  // Handles form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      profile: { ...prev.profile, [name]: value },
    }));
  };

  // Function to move to the next step
  const handleNextStep = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  // Function to move to the previous step
  const handlePreviousStep = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };

  // Handle submission of the final step
  const handleSubmit = () => {
    // Here you would handle sending data to Firebase, Mixpanel, or any other service
    console.log('Creator challenge onboarding completed', formData);
    navigate('/creator-dashboard'); // Navigate to creator dashboard after onboarding
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-6">
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-lg">
        <h2 className="text-2xl font-bold mb-4">
          {currentStep === 1 && 'Step 1: Create Your Profile'}
          {currentStep === 2 && 'Step 2: Client Information'}
          {currentStep === 3 && 'Step 3: Cold Outreach Setup'}
        </h2>

        {currentStep === 1 && (
          <>
            <h3 className="text-lg font-semibold mb-4">Let’s set up your profile</h3>
            <input
              type="text"
              name="name"
              placeholder="Full Name"
              value={formData.profile.name}
              onChange={handleInputChange}
              className="w-full p-2 border rounded mb-4"
            />
            <input
              type="text"
              name="username"
              placeholder="Username"
              value={formData.profile.username}
              onChange={handleInputChange}
              className="w-full p-2 border rounded mb-4"
            />
            <textarea
              name="bio"
              placeholder="Bio"
              value={formData.profile.bio}
              onChange={handleInputChange}
              className="w-full p-2 border rounded mb-4"
              rows="4"
            ></textarea>
            <button
              onClick={handleNextStep}
              className="bg-orange-500 text-white py-2 px-4 rounded hover:bg-orange-600 transition duration-300"
            >
              Next
            </button>
          </>
        )}

        {currentStep === 2 && (
          <>
            <h3 className="text-lg font-semibold mb-4">Tell us about your clients</h3>
            <label className="block mb-2">
              Have you worked with clients before?
              <input
                type="checkbox"
                checked={formData.clientInfo.pastClients}
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    clientInfo: { ...prev.clientInfo, pastClients: e.target.checked },
                  }))
                }
                className="ml-2"
              />
            </label>
            <label className="block mb-2">
              Are you still in contact with previous clients?
              <input
                type="checkbox"
                checked={formData.clientInfo.contactWithClients}
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    clientInfo: { ...prev.clientInfo, contactWithClients: e.target.checked },
                  }))
                }
                className="ml-2"
              />
            </label>
            <textarea
              name="testimonials"
              placeholder="Any testimonials or case studies?"
              value={formData.clientInfo.testimonials}
              onChange={(e) =>
                setFormData((prev) => ({
                  ...prev,
                  clientInfo: { ...prev.clientInfo, testimonials: e.target.value },
                }))
              }
              className="w-full p-2 border rounded mb-4"
              rows="4"
            ></textarea>
            <button
              onClick={handlePreviousStep}
              className="bg-orange-300 text-black py-2 px-4 rounded hover:bg-orange-400 mr-4 transition duration-300"
            >
              Back
            </button>
            <button
              onClick={handleNextStep}
              className="bg-orange-500 text-white py-2 px-4 rounded hover:bg-orange-600 transition duration-300"
            >
              Next
            </button>
          </>
        )}

        {currentStep === 3 && (
          <>
            <h3 className="text-lg font-semibold mb-4">Cold Outreach Campaign</h3>
            <p className="mb-4">
              You will be able to set up a cold outreach campaign to help gain new clients. Use AI-powered
              templates and follow-up suggestions to tailor your approach.
            </p>
            <button
              onClick={handlePreviousStep}
              className="bg-orange-300 text-black py-2 px-4 rounded hover:bg-orange-400 mr-4 transition duration-300"
            >
              Back
            </button>
            <button
              onClick={handleSubmit}
              className="bg-orange-500 text-white py-2 px-4 rounded hover:bg-orange-600 transition duration-300"
            >
              Complete Setup
            </button>
          </>
        )}
      </div>
    </div>
  );
}

// ServiceList.js
import React from 'react';

export default function ServiceList({ services, onSelect }) {
  if (services.length === 0) {
    return null;
  }

  return (
    <ul className="mt-2 divide-y divide-gray-200 border border-gray-200 rounded-md dark:divide-gray-700 dark:border-gray-700">
      {services.map((service) => (
        <li
          key={service.name}
          className="px-4 py-2 hover:bg-gray-100 cursor-pointer dark:hover:bg-gray-600"
          onClick={() => onSelect(service)}
        >
          <div className="flex justify-between items-center">
            <span>{service.name}</span>
            <span className="text-sm text-gray-500 dark:text-gray-400">
              {service.currency} {service.price}
            </span>
          </div>
        </li>
      ))}
    </ul>
  );
}
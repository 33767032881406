// In useAuthAndEmailVerification.js
import { useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

const useAuthAndEmailVerification = () => {
  const [authState, setAuthState] = useState({
    isAuthenticated: false,
    isEmailVerified: false,
    user: null,
    isLoading: true,
  });

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setAuthState({
        isAuthenticated: !!user,
        isEmailVerified: user ? user.emailVerified : false,
        user: user,
        isLoading: false,
      });
    });

    return () => unsubscribe();
  }, []);

  return authState;
};

export default useAuthAndEmailVerification;
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';

export default function OnboardingSelection() {
  const [selectedOption, setSelectedOption] = useState(null);
  const navigate = useNavigate();

  const handleSelection = (option) => {
    setSelectedOption(option);
    // Store the user's choice in localStorage
    localStorage.setItem('onboardingChoice', option);
    // Navigate to sign-up page regardless of the choice
    navigate('/signup');
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-br from-orange-400 to-orange-600 text-white p-4">
      <motion.h1 
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="text-5xl font-extrabold mb-8 text-center"
      >
        Choose Your Path
      </motion.h1>
      <div className="flex flex-col md:flex-row space-y-6 md:space-y-0 md:space-x-8">
        <PathOption
          title="Regular Onboarding"
          description="Dive into Klorah's innovative features and services. Perfect for those who want to explore at their own pace."
          icon="🚀"
          onClick={() => handleSelection('regular')}
        />
        <PathOption
          title="Creator Challenge"
          description="Embark on our 30-day journey to boost your creative potential and land a client. You'll still have full access to all Klorah tools!"
          icon="🎨"
          onClick={() => handleSelection('creatorChallenge')}
        />
      </div>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.2 }}
        className="mt-8 text-center max-w-2xl"
      >
        <h2 className="text-2xl font-bold mb-4">About the Creator Challenge</h2>
        <p className="mb-4">
          The Creator Challenge is an intensive 30-day program designed to kickstart your freelance career. 
          You'll receive daily tasks, expert guidance, and personalized feedback to help you build a 
          standout profile, connect with potential clients, and land your first (or next) gig.
        </p>
        <p className="font-semibold">
          Remember: Whether you choose the Creator Challenge or Regular Onboarding, you'll have full access 
          to all of Klorah's powerful tools and features. The challenge simply provides extra structure and 
          motivation to help you achieve your goals faster!
        </p>
      </motion.div>
    </div>
  );
}

function PathOption({ title, description, icon, onClick }) {
  return (
    <motion.div
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      className="w-full md:w-80 bg-white bg-opacity-10 rounded-xl overflow-hidden backdrop-filter backdrop-blur-lg shadow-lg border border-orange-200"
    >
      <button
        onClick={onClick}
        className="w-full h-full p-6 text-left transition duration-300 hover:bg-white hover:bg-opacity-20"
      >
        <div className="text-4xl mb-4">{icon}</div>
        <h3 className="text-2xl font-bold mb-2">{title}</h3>
        <p className="text-sm opacity-80">{description}</p>
      </button>
    </motion.div>
  );
}
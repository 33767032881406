import React from 'react';
import { ClimbingBoxLoader } from 'react-spinners';

const LoadingPage = () => {
  return (
    <div className="flex justify-center items-center h-screen bg-white dark:bg-black">
      <ClimbingBoxLoader color={document.documentElement.classList.contains('dark') ? "#FFF" : "#000"} size={50} />
    </div>
  );
};

export default LoadingPage;

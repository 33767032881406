import React, { useState } from 'react';
import { toast } from 'react-toastify';

const ServiceDetailsWithBookingForm = ({ service, closeModal, freelancerEmail }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');

  const sendEmail = async (email, subject, htmlContent) => {
    try {
      const response = await fetch('https://klorah-fast-server-9266fe8d441a.herokuapp.com/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          to: email,
          subject: subject,
          html_content: htmlContent
        })
      });

      if (!response.ok) {
        throw new Error('Failed to send email');
      }

      const result = await response.json();
      toast.success('Email sent successfully');
      console.log('Email response:', result);
    } catch (error) {
      toast.error('Error sending email: ' + error.message);
      console.error('Error sending email:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    try {
      if (!freelancerEmail) {
        setError('Freelancer email not found. Please ask the freelancer to add an email to their contact section.');
        setLoading(false);
        return;
      }

      const subject = `You've got a new booking request for ${service.name} on Klorah!`;
      const htmlContent = `
        <h2>Hi there,</h2>
        <p>We're excited to let you know that you've received a new booking request on Klorah. Here are the details:</p>
        <p><strong>Service:</strong> ${service.name}</p>
        <p><strong>Description:</strong> ${service.description}</p>
        <p><strong>Price:</strong> ${service.price} ${service.currency}</p>
        <p><strong>Client's Name:</strong> ${name}</p>
        <p><strong>Client's Email:</strong> ${email}</p>
        <p><strong>Client's Message:</strong> ${message}</p>
        <p>We hope this new booking request brings you joy and success. Thank you for being a part of the Klorah community!</p>
      `;

      await sendEmail(freelancerEmail, subject, htmlContent);

      setSuccess(true);
      setName('');
      setEmail('');
      setMessage('');
    } catch (error) {
      setError(error.message);
    }

    setLoading(false);
  };

  return (
    <div className="max-w-md mx-auto p-6">
      <h2 className="text-3xl font-bold mb-6 text-gray-800 dark:text-white">{service.name}</h2>
      <p className="text-gray-600 dark:text-gray-300 mb-4">{service.description}</p>
      <p className="font-bold text-gray-800 dark:text-white mb-6">${service.price} {service.currency}</p>

      <h3 className="text-2xl font-bold mb-4 dark:text-white">Book Service</h3>
      {success ? (
        <div className="bg-green-100 dark:bg-green-800 text-green-800 dark:text-green-100 p-4 rounded mb-4">
          Your booking request has been sent successfully!
        </div>
      ) : (
        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label htmlFor="name" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
              Name
            </label>
            <input
              type="text"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              className="w-full px-4 py-2 border border-gray-300 dark:border-gray-600 dark:bg-gray-700 dark:text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
              Email
            </label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="w-full px-4 py-2 border border-gray-300 dark:border-gray-600 dark:bg-gray-700 dark:text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div>
            <label htmlFor="message" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
              Message
            </label>
            <textarea
              id="message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
              className="w-full px-4 py-2 border border-gray-300 dark:border-gray-600 dark:bg-gray-700 dark:text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            ></textarea>
          </div>
          {error && (
            <div className="text-red-500 dark:text-red-300">{error}</div>
          )}
          <button
            type="submit"
            disabled={loading}
            className="w-full py-3 bg-blue-600 dark:bg-blue-700 text-white font-semibold rounded-lg hover:bg-blue-700 dark:hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            {loading ? 'Sending...' : 'Send Booking Request'}
          </button>
        </form>
      )}
    </div>
  );
};

export default ServiceDetailsWithBookingForm;

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { motion } from "framer-motion";
import { getAuth, signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { doc, getDoc, setDoc } from "firebase/firestore";
import app, { db } from '../Firebase';

export default function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [rememberMe, setRememberMe] = useState(true);
    const navigate = useNavigate();
    
    const auth = getAuth();
    const provider = new GoogleAuthProvider();

    const handleLoginWithEmail = async (event) => {
        event.preventDefault();

        if (rememberMe) {
            localStorage.setItem('rememberMe', 'true');
        } else {
            localStorage.removeItem('rememberMe');
        }

        try {
            await signInWithEmailAndPassword(auth, email, password);
            navigate('/dashboard');
        } catch (error) {
            handleAuthErrors(error);
        }
    };

    const handleGoogleSignIn = async () => {
        try {
            const result = await signInWithPopup(auth, provider);
            const user = result.user;
            const userRef = doc(db, 'users', user.uid);
            const docSnap = await getDoc(userRef);

            if (!docSnap.exists()) {
                await setDoc(userRef, {
                    userId: user.uid,
                    username: user.displayName || 'Anonymous',
                    email: user.email,
                    isPro: false,
                    invoicesCreated: 0,
                    agreementsCreated: 0,
                    stripeOnboarded: false,
                });
                navigate('/onboarding-questions');
            } else {
                if (rememberMe) {
                    localStorage.setItem('rememberMe', 'true');
                } else {
                    localStorage.removeItem('rememberMe');
                }
                navigate('/dashboard');
            }
        } catch (error) {
            const errorMessage = 'Failed to sign in with Google. Please try again later.';
            setErrorMessage(errorMessage);
        }
    };

    function handleAuthErrors(error) {
        let errorMessage = '';
        if (error.code === 'auth/user-not-found') {
            errorMessage = 'Invalid email address.';
        } else if (error.code === 'auth/wrong-password') {
            errorMessage = 'Invalid password.';
        } else {
            errorMessage = 'Failed to sign in. Please try again later.';
        }
        setErrorMessage(errorMessage);
    }

    return (
        <div className="min-h-screen flex" style={{
            backgroundImage: "url('./freelancer.jpg')",
            backgroundSize: 'cover',
            backgroundPosition: 'center',
        }}>
            <div style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
            }} />
            <div className="relative z-10 flex flex-col justify-center py-12 sm:px-6 lg:px-8 w-full max-w-md mx-auto">
                <motion.div
                    initial={{ opacity: 0, y: -20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                >
                    <h1 className="text-center text-4xl font-extrabold text-white">Klorah</h1>
                    <p className="mt-2 text-center text-sm text-gray-300">
                        Your ultimate invoicing solution
                    </p>
                </motion.div>

                <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: 0.2 }}
                    className="mt-8"
                >
                    <div className="bg-white py-8 px-4 shadow-lg sm:rounded-lg sm:px-10">
                        <p className="text-sm text-center font-medium text-gray-700 mb-6">
                            New to Klorah?{" "}
                            <Link to="/signup" className="text-blue-600 hover:underline">
                                Create a free account
                            </Link>
                        </p>
                        <form onSubmit={handleLoginWithEmail} className="space-y-6">
                            {errorMessage && <p className="text-red-500 mb-4">{errorMessage}</p>}
                            <div>
                                <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
                                <input
                                    id="email"
                                    name="email"
                                    type="email"
                                    autoComplete="email"
                                    required
                                    className="mt-1 p-2 w-full border border-gray-300 rounded-md"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>
                            <div>
                                <label htmlFor="password" className="block text-sm font-medium text-gray-700">Password</label>
                                <input
                                    id="password"
                                    name="password"
                                    type="password"
                                    required
                                    className="mt-1 p-2 w-full border border-gray-300 rounded-md"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            </div>
                            <div className="flex items-center justify-between">
                                <div className="flex items-center">
                                    <input
                                        type="checkbox"
                                        id="rememberMe"
                                        name="rememberMe"
                                        checked={rememberMe}
                                        onChange={(e) => setRememberMe(e.target.checked)}
                                        className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                                    />
                                    <label htmlFor="rememberMe" className="ml-2 text-sm text-gray-700">Remember me</label>
                                </div>
                                <Link to="/auth/forgot-password" className="text-sm text-blue-600 hover:underline">Forgot password?</Link>
                            </div>
                            <button type="submit" className="w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-white bg-black hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                                Sign in
                            </button>
                            <div className="mt-6">
                                <div className="relative">
                                    <div className="absolute inset-0 flex items-center">
                                        <div className="w-full border-t border-gray-300" />
                                    </div>
                                    <div className="relative flex justify-center text-sm">
                                        <span className="px-2 bg-white text-gray-500">Or continue with</span>
                                    </div>
                                </div>
                                <div className="mt-6">
                                    <button type="button" onClick={handleGoogleSignIn} className="w-full py-2 px-4 border border-gray-300 rounded-md shadow-sm flex items-center justify-center text-gray-700 bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                                        <img src="/googleIcon.svg" alt="Google Logo" className="h-6 w-6 mr-2" />
                                        Sign in with Google
                                    </button>
                                </div>
                            </div>
                            <div className="mt-4 flex justify-between">
                                <button type="button" onClick={() => navigate('/tos')} className="text-gray-600 hover:underline focus:outline-none">
                                    Terms of Service
                                </button>
                                <button type="button" onClick={() => navigate('/privacy-policy')} className="text-gray-600 hover:underline focus:outline-none">
                                    Privacy Policy
                                </button>
                            </div>
                        </form>
                    </div>
                </motion.div>
            </div>
        </div>
    );
}
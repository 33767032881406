import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';

export const generatePdfFromHtml = async (htmlContent, fileName) => {
  const element = document.createElement('div');
  element.style.width = '800px'; // Adjust the width as necessary
  element.innerHTML = `
    <style>
      body {
        font-family: Arial, sans-serif;
        font-size: 12px;
        line-height: 1.5;
        margin: 20px;
      }
      h1 {
        font-size: 16px;
        margin-bottom: 20px;
      }
      p {
        margin-bottom: 10px;
      }
      .section {
        margin-bottom: 30px;
      }
    </style>
    <div class="section">
      ${htmlContent.replace(/\n/g, '<br>')}
    </div>
  `;
  document.body.appendChild(element);

  const canvas = await html2canvas(element, { scale: 2, scrollY: -window.scrollY });
  const imgData = canvas.toDataURL('image/png');

  const pdf = new jsPDF({
    orientation: 'p',
    unit: 'pt',
    format: 'a4',
  });

  const pdfWidth = pdf.internal.pageSize.getWidth();
  const pdfHeight = pdf.internal.pageSize.getHeight();
  const margin = 20; // Adjust the margin to give more room
  const imgProps = pdf.getImageProperties(imgData);
  const imgWidth = pdfWidth - 2 * margin;
  const imgHeight = (imgProps.height * imgWidth) / imgProps.width;

  let position = 0;
  while (position < imgHeight) {
    const remainingHeight = imgHeight - position;
    const pageHeight = Math.min(remainingHeight, pdfHeight - 2 * margin);

    pdf.addImage(imgData, 'PNG', margin, margin, imgWidth, pageHeight, undefined, 'FAST', undefined, undefined, undefined, position / imgHeight);
    position += pageHeight;

    if (position < imgHeight) {
      pdf.addPage();
    }
  }

  const pdfBlob = pdf.output('blob');
  const url = URL.createObjectURL(pdfBlob);
  const link = document.createElement('a');
  link.href = url;
  link.download = `${fileName}-unsigned.pdf`;
  link.click();
  URL.revokeObjectURL(url);

  document.body.removeChild(element);
};
